import { useEffect, useState } from 'react';
import {
  simpleAxiosGet
} from '../../../Utils';

const Image = ({ linkImage, width, height, fit, type, sClass, alt }) => {

  const [b64img, set_b64img] = useState('')

  const getImage = async (linkImage, width, height, fit) => {

    linkImage = linkImage.replace("temp.assec.pt", "cdn.assec.app")
    // await simpleWget('cdn.assec.app', '/repo/image.php?img=' + linkImage + '&w=' + width + '&h=' + height + '&fit=' + fit)
    // await simpleGet('cdn.assec.app/repo/image.php?img=' + linkImage + '&w=' + width + '&h=' + height + '&fit=' + fit)
    // console.log('---> ', 'https://cdn.assec.app/repo/image.php?img=' + linkImage + '&w=' + width + '&h=' + height + '&fit=' + fit)
    try {


      let res = await simpleAxiosGet('https://cdn.assec.app','/repo/image.php?img=' + linkImage + '&w=' + width + '&h=' + height + '&fit=' + fit)
      /* .then((res) => {
        // console.log('res', res)
      
      }) */
      set_b64img(res)
      return true
    } catch (error) {
      console.log('getImage error', error)

      return false

    }
  }

  useEffect(() => {

    if (linkImage !== undefined) {

      getImage(linkImage, width, height, fit)
      // getImage(linkImage, width, height, fit)
      //console.log('result', result)
      //set_b64img(result)

    }
    else {
      set_b64img('')
    }

  }, [linkImage, width, height, fit])

  return (
    b64img !== undefined && b64img !== '' ?
      type === 'img' ?
        <img src={b64img} className={sClass} width={width} height={height} alt={alt} />
        :
        b64img
      :
      ''
  )
};

export default Image;
