import React, { useState, useEffect, useRef } from 'react'

// import Loader from '../../../loader';

import HomeBrands01 from './HomeBrands_01';
import HomeBrands02 from './HomeBrands_02';
import HomeBrands03 from './HomeBrands_03';
import HomeBrands04 from './HomeBrands_04';

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const layout = useRef('')
	const content = useRef({})
	const pw = useRef({})

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(content.current = props.dados_config)
            set_dados(pw.current = props.pag_welcome)
            set_dados(layout.current = props.layout)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		null
    :
        <>
            {
            layout.current === 'home-brands-01' ?
                <HomeBrands01 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            layout.current === 'home-brands-02' ?
                <HomeBrands02 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            layout.current === 'home-brands-03' ?
                <HomeBrands03 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            layout.current === 'home-brands-04' ?
                <HomeBrands04 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
                <HomeBrands01 pag_welcome={pw.current} dados_config={dados_config.current} />
            }
        </>
    )
}
