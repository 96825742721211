import React, { useState, useEffect } from 'react';
//import {useLocation } from 'react-router-dom';

import "../Pagina.css";

import parse from 'html-react-parser';

import { language } from '../WEBContent/Language';

import {
    //set_language,
    content_language

} from '../../../Utils';

import CookieConsent from "react-cookie-consent";

//import * as Hosts from "../../../Hosts";


const Cookies = (props) => {

    const [template, set_template] = useState('');
    const [theme, set_theme] = useState('');
    const [dados_config, set_dados_config] = useState({});

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [/*isloading*/, setLoading] = useState(true);

    //const page_location = useLocation().pathname.split('/').pop();

    useEffect(() => {

        if(props.item !== undefined && props.item !== null && props.item !== '') {
            set_template(props.item?.template)
            set_theme(props.item?.theme)
            set_dados_config(props.item?.dados_config)
        }

        /*if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
        }
        else {
            set_lang(localStorage.getItem('lang'))
        }*/

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        
            setLoading(false)
            }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        
            setLoading(false)
        }

    }, [props])

    return (

        <CookieConsent
            //debug={true}
            ariaAcceptLabel={language[lang].cookie_button_text}
            buttonText={language[lang].cookie_button_text}
            buttonClasses={"btn-cookies"}
            containerClasses={"containerCookie " + template + ' ' + theme}
            contentClasses="messageCookie"
        >
            <a className="visually-hidden-focusable" href="#navbar">Skip to main content</a>
            <div className="col-12 text-center">
                {parse(language[lang].cookies_message)}&nbsp;
                {
                    lang !== 'pt' && dados_config.content !== undefined && dados_config.content[0]?.terms !== undefined && Object.keys(dados_config.content[0]?.terms).length > 0 && dados_config.content[0]?.terms[lang_id] !== undefined && dados_config.content[0]?.terms[lang_id] !== null && dados_config.content[0]?.terms[lang_id] !== '' ?
                        <a href={dados_config.content[0]?.terms[lang_id]?.link} target="_blank" rel="noreferrer">
                            {language[lang].more_information}
                        </a>
                    :
                        dados_config.content !== undefined && dados_config.content[0]?.terms !== undefined && Object.keys(dados_config.content[0]?.terms).length > 0 && dados_config.content[0]?.terms['1'] !== null && dados_config.content[0]?.terms['1'] !== '' ?
                            <a href={dados_config.content[0]?.terms['1']?.link} target="_blank" rel="noreferrer">
                                {language[lang].more_information}
                            </a>
                        :
                            <a className="pe-none" href='!#' target="_blank" rel="noreferrer">
                                {language[lang].more_information}
                            </a>
                }
            </div>
        </CookieConsent>
    )
};
export default Cookies;