import React, { useState, useEffect, useRef } from 'react';

import { reqGET, repo_img_link, content_language, translate } from '../../../../Utils';

import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import AssecImage from "../../ASSEC_Image/Image"

import parse from 'html-react-parser';

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeServices_04.css"

const HomeServices = (props) => {

	const [, set_dados] = useState(null)
	const services_list = useRef([])
	const dados_config = useRef([])

    const [services_title, set_services_title] = useState('')
    const [services_subtitle, set_services_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'service' && el.info.active) {
                    set_services_title(translate(el.info.title, lang))
                    set_services_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getServices = async () => {
            reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/service`)
                .then(res => {

                    set_dados(services_list.current = res.data)
                    setLoading(false)

                }).catch(erro => console.log('Erro1', erro))
        }
        getServices()

    }, [props, lang])

    function get_image_content(el, type) {

        let result = ''
    
    
        if(parseInt(lang_id) !== 1 && el.content[0]['language'] !== undefined && el.content[0]['language'][lang_id] !== undefined && el.content[0]['language'][lang_id] !== null && el.content[0]['language'][lang_id]['media'] !== null && el.content[0]['language'][lang_id]['media']['image'] !== undefined && el.content[0].language[lang_id]['media']['image'].length > 0) { 
            if(type === 'name') {
                result = el.content[0].language[lang_id].media.image[0].name
            }
            else if(type === 'url') {
                result = el.content[0].language[lang_id].media.image[0].url
            }
            else if(type === 'link') {
                result = el.content[0].language[lang_id].media.image[0].link
            }
        } else {
            if(el.content[0].media.image !== undefined && el.content[0].media.image !== null && el.content[0].media.image.length > 0) {

                if(type === 'name') {
                    result = el.content[0].media.image[0].name
                }
                else if(type === 'url') {
                    result = el.content[0].media.image[0].url
                }
                else if(type === 'link') {
                    result = el.content[0].media.image[0].link
                }
    
            }
        }

        return result
    }

    return (
        isloading ?
          <Loader />
		  :
          <section id="homeservices_04" className='div-component'>
            <div className="container">
              <div className="row">
                <div className="col-12 title-properties">
                  <h3 className="titulo-pp">
                    {services_title}
                  </h3>
                  {
                    services_subtitle !== '' ?
                      <h4 className={"subtitulo-pp"}>
                        {services_subtitle}
                      </h4>
                    :
                      null
                  }
                  <hr></hr>
                </div>
                {
                  services_list.current !== undefined && services_list.current !== null && services_list.current.length > 0 ?
                    <>
                      <div className="col-12">
                        <div className="row gy-5 justify-content-center">
                          {
                          services_list.current.map((v, k) => {
                            if(k < 4) {
                              return (
                                <div className="col-lg-6" key={k}>
                                  <a href={"service/" + v.id} title={language[lang].learn_more + ' - ' + (lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))} >
                                    <div className='service-container-layout-4'>
                                      <div className="service-image">
                                        <AssecImage
                                          linkImage={get_image_content(v, 'link') !== '' ? get_image_content(v, 'link') : repo_img_link('nophoto.webp')}
                                          width="300"
                                          height="200"
                                          fit='crop'
                                          type='img'
                                          sClass='img-fluid'
                                          alt={get_image_content(v, 'name')}
                                          title={get_image_content(v, 'name')}
                                        />
                                      </div>
                                      <div className="service-content-layout-4">
                                        <div className="service-title">
                                          {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}  
                                        </div>
                                        <div className="service-description">
                                            {parse((lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text)))}
                                          </div>
                                    </div>
                                    </div>
                                  </a>
                                </div>
                              )
                            } else { 
                              return null
                            }
                          })              
                          }
                        </div>
                      </div>
                    </> 
                  :
                    null
                }
                <div className="col-12 mt-5 text-center position-relative">
                  <a className="btn btn-primary" href={'./service/all'} >{language[lang].see_all}</a>
                </div>
              </div>
            </div>
          </section>
    )
};
export default HomeServices;