import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { repo_logo_link, content_language, translate } from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = (props) => {

  const [, set_dados] = useState(null)
	const dados_config = useRef({})

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [, set_lang_id] = useState('1');

  const location = useLocation().pathname;

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
      childs: []
    }
  }

  const navbar = useRef(data_interface_navbar);

  const [isloading, setLoading] = useState(true);

  useEffect(() => {
  
    if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
      set_dados(navbar.current = props.dados_navbar)
    }

    if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
      set_dados(dados_config.current = props.dados_config)
    }

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_lang_id('1')

      setLoading(false)
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })

      setLoading(false)
    }

  }, [props])
  
  const page_location = useLocation().pathname.split('/').pop();

  return isloading ?
    null
    :
    <footer id="footer">
      <h4 className="footer-heading visually-hidden-focusable">
        Footer - {Hosts.webSiteTitle}
      </h4>
      <div className="container">
        <div className="row pt-5 pb-1">
          <div className="col-12 mb-2 d-none">
            <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} >
              <img src={repo_logo_link("logo.svg")} width="180" height="75" alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="img-fluid" />
            </a>
          </div>

          <div className="col-12 col-lg-5">
            <h5 className="my-2">{language[lang].about_us}</h5>
              {
                dados_config.current.content !== undefined ?
                  <p>
                    {parse(translate(dados_config.current.content[0].description, lang))}
                  </p>
                :
                  null
              }
          </div>

          <div className="col-6 col-md-4 col-lg-2">
            <h5 className="my-2">{language[lang].site_map}</h5>
            {
              navbar.current.content.childs.map((v, key) => {
                let link = ''
                let current_top_check = ''
                v.id_content !== '' ?
                  link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                  :
                  link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                if (v.children !== undefined && v.children !== '' && v.children.length > 0) {

                  v.children[0].id_content !== '' ?
                    link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : Hosts.WEB_SITE_URI + 'pag/' + v.children[0].id_content)
                    :
                    link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : (v.children[0].link.substr(0, 4) !== 'http' && v.children[0].link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.children[0].link : (v.children[0].link.substr(0, 3) === 'www' ? 'http://' + v.children[0].link : v.children[0].link)))

                  current_top_check = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? v.children[0].slug : v.children[0].id_content);

                  if (current_top_check === '') {
                    if (location === '/' + v.children[0].link) {
                      current_top_check = v.children[0].link
                    }
                  }
                }

                return (
                  v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                    <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')}>
                      <h6 className="mb-2">
                        {v.title}
                      </h6>
                    </a>
                    :
                    <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')} target="_blank" rel="noreferrer">
                      <h6 className="mb-2">
                        {v.title}
                      </h6>
                    </a>
                )
              })
            }
          </div>

          <div className="col-6 col-md-4 col-lg-2">
            <h5 className=" my-2">{language[lang].legal_information}</h5>
            {
            dados_config.current.content !== undefined && dados_config.current.content[0].terms.link !== '' ?
              <a href={dados_config.current.content[0].terms.link}>
                <h6 className="mb-2">{language[lang].terms_conditions}</h6>
              </a>
            :
              null
            }
            
            <a href="https://www.livroreclamacoes.pt/" title={"Link - " + language[lang].complaint_book} target="_blank" rel="noreferrer">
              <h6 className="mb-2">{language[lang].complaint_book}</h6>
            </a>
          </div>

          <div className="col-12 col-md-4 col-lg-3 text-center text-sm-start social-media">
            <h5 className="my-2">{language[lang].social_media}</h5>
            {
              dados_config.current.content !== undefined ?
                dados_config.current.content[0].social.map((el, k) => {
                  if(el.link !== '') {
                    return (
                      <a key={k} className="me-3" href={el.link.includes('http') ? el.link : 'http://' + el.link} title={el.info.name} target="_blank" rel="noreferrer" >
                        <i className={"bi bi-" + el.info.icon.bi}></i>
                      </a>
                    )
                  }
                  else {
                    return null
                  }
                })
              :
                null
            }
          </div>
        </div>
      </div>
      <div className="col-12 text-center py-3 justify-content-center text-white">
        <ul className="list-inline mb-0 copyright">
          <li className="list-inline-item p-0">
            <span>
              <i className="bi bi-c-circle"></i>&nbsp;2024&nbsp;{Hosts.webSiteTitle}
            </span>
          </li>
          <li className="list-inline-item p-0">
            <span className="px-1">&#124;</span>
          </li>
          <li className="list-inline-item p-0">
            <span>{language[lang].developed}</span>
          </li>
          <li className="list-inline-item p-0">
            <span>
              <a href="http://sim.assec.pt" title="Website Assec Sim!" target="_blank" rel="noreferrer">
                {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                Assec Sim!
              </a>
            </span>
          </li>
        </ul>
      </div>
    </footer>


};
export default Footer;