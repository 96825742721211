import React, { useState, useEffect } from 'react';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_img_link,translate } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

//import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

export default function News(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [link, set_link] = useState('')
    const [news_title, set_news_title] = useState('')
    let last_i = 0

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            for (let el of props.info.state.dados_config.content[0].components) {

                if(el.info.tag === 'news' && el.info.active) {
                    set_news_title(translate(el.info.title, lang))
                }
            }

            if(props.info.state.dados_content.info.source !== undefined && props.info.state.dados_content.info.source['text'] !== undefined && props.info.state.dados_content.info.source['text'] !== '') {
                const { hostname } = new URL(props.info.state.dados_content.info.source.text)
                set_link(hostname)
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            
			let id = props.info.state.id;

            if(id !== '') {
                let getInfoList = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                        .then(res => { 
                            if (res.data.length > 0) {

                                /*if(res.data[0].info.source !== undefined && res.data[0].info.source['text'] !== undefined && res.data[0].info.source['text'] !== '') {
                                    const { hostname } = new URL(res.data[0].info.source.text)
                                    set_link(hostname)
                                }*/
    
                                var hoje = new Date();
                                let aux = []
                                res.data.forEach((v, k) => {
                                  if(v.info.date.published !== undefined && v.info.date.published !== '' && aux.length <= 2) {
                                      var date_p = new Date(v.info.date.published);
                        
                                      if(hoje >= date_p) {
                                        if(v.info.date.avaiable !== '') {
                                            var date_a = new Date(v.info.date.avaiable);
    
                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        }
                                        else {
                                            aux.push(v)
                                        }
                                      }
                                  }
                                })
                                set_list_content(aux)
                                
                                setLoading(false)
                              }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
                getInfoList()
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props, lang]);

    return (isloading ?
		<Loader />
	:
        <>
            {
                content.info['preview'] === true ? 
                    <Preview />
                : 
                    null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="article my-5">
                <div className="container">
                    <div className="row">
                        {
                            content.content.map((el, k) => {  
                                return (
                                    <div id="article-detail" key={k} className={list_content.length > 1  ? 'col-12 col-md-9' : 'col-12'}>
                                        <h1 className={el.title !== '' ? "article-title mb-3" : "d-none"}>
                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                        </h1>

                                        <div className={"article-abstract mb-3"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                        </div>

                                        {/*<div className="article-categories d-none">
                                            <i className="bi bi-tag" />
                                            {
                                                content.info.tags.map((v, k) => {
                                                    return (
                                                        <span key={k} className="pill-tag">{v.text}</span>
                                                    )
                                                })
                                            }
                                        </div>*/}

                                        <div className={"article-source mb-3"}>
                                            {
                                                content.info.date !== undefined && content.info.date['published'] !== '' ?
                                                    <span>
                                                        {content.info.date['published'].replace("T", " ")}
                                                    </span>
                                                :
                                                    null
                                            }
                                            
                                            {
                                                content.info.source !== undefined && content.info.source['text'] !== undefined && content.info.source['text'] !== '' ? 
                                                    <span>
                                                        {language[lang].source_text}
                                                        {
                                                        content.info.source['text'].includes("http") ? 
                                                            <a href={content.info.source['text']} target="_blank" rel="noreferrer">{link}</a> 
                                                        : 
                                                            <a href={'http://' + content.info.source['text']} target="_blank" rel="noreferrer">{link}</a> 
                                                        }
                                                    </span> 
                                                : 
                                                    null
                                            }

                                            {
                                                content.info.source !== undefined && content.info.source['image'] !== undefined && content.info.source['image'] !== '' ? 
                                                    <span>
                                                        {language[lang].source_image}
                                                        {
                                                            content.info.source !== undefined && content.info.source['image'] !== '' ? 
                                                                content.info.source['image'] 
                                                            : 
                                                                null
                                                        }
                                                    </span>
                                                : 
                                                    null
                                            }
                                            
                                        </div>
                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                <div className={"article-image mb-3 mb-md-5"}>
                                                    <AssecImage 
                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                        width="980"
                                                        height="540"
                                                        fit='crop'
                                                        type='img'
                                                        sClass="img-fluid rounded shadow-sm" 
                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                    />
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                    <div className={"article-image mb-3 mb-md-5"}>
                                                        <AssecImage 
                                                            linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                            width="980"
                                                            height="540"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid rounded shadow-sm" 
                                                            alt={el['media']['image'][0]['name']}
                                                        />
                                                    </div>
                                                :
                                                    null
                                        }
                                        {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm mt-2 mb-3" alt="" />*/}
                                        {/*<AssecImage 
                                            linkImage={repo_img_link('nophoto.webp')}
                                            width="980"
                                            height="540"
                                            fit='crop'
                                            type='img'
                                            sClass="img-fluid rounded shadow-sm" 
                                            alt={'no_photo'}
                                        />*/}
                                        
                                        <div className={"article-text"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                        </div>

                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                            <div className="row">
                                                <div className="col-12 article-gallery mt-3">
                                                    <div className='row'>
                                                        {
                                                        el.media.image.map((el_img, k_img) => {
                                                            if(k_img > 0) {
                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-3 col-lg-2 mb-4">
                                                                        <figure className='figura m-0'>
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid shadow-sm" 
                                                                                alt={el_img.name}
                                                                            />
                                                                            {
                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                    ''
                                                                                :
                                                                                    <figcaption className='figure-caption p-3 text-center' >{el_img.name}</figcaption>
                                                                            }
                                                                        </figure>
                                                                    </div>
                                                                )
                                                            }
                                                            else 
                                                                return null
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                <div className="row">
                                                    <div className="col-12 article-documents mt-3">
                                                        {
                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                return (
                                                                    <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                    </a>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                    <div className="row">
                                                        <div className="col-12 article-documents mt-3">
                                                            {
                                                            el.media.doc.map((el_doc, k_doc) => {
                                                                return (
                                                                    <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} target="_blank" rel="noreferrer">
                                                                        {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                    </a>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                        }

                                        {/*<div className="row">
                                            <div className="div-social col-12 mt-3 mb-3 mb-md-5">
                                                <DivSharer link={Hosts.EMAIL_WEB_SITE_URL + pathname} description={get_seo_data('description')} />
                                            </div>
                                        </div>*/}
                                    </div>
                                )
                            })
                        }
                        {
                            list_content.length > 1  ?
                                <div id="latest-news" className="col-12 col-md-3">
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            {
                                                news_title !== '' ?
                                                    <h3 className='subtitulo-pp'>{news_title}</h3>
                                                :   
                                                    <h3 className='subtitulo-pp'>{language[lang].news}</h3>
                                            }
                                        </div>
                                        {
                                        list_content.map((content_list) => {
                                            return (
                                                content_list.content.map((el, k) => {  
                                                    if(content_list.id !== content.id && last_i < 2) {
                                                        last_i = last_i + 1
                                                        return (
                                                            <div key={k} className="col-12 mb-3">
                                                                <div className="card border-0 shadow-sm">
                                                                    <a href={'./article/' + content_list.id} title={el.title}>
                                                                        <div className="article-image d-none d-md-block">
                                                                            {
                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                                    <AssecImage 
                                                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                                    />
                                                                                :
                                                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                        <AssecImage 
                                                                                            linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el['media']['image'][0]['name']}
                                                                                        />
                                                                                    :
                                                                                        <AssecImage 
                                                                                            linkImage={repo_img_link('nophoto.webp')}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={'no_photo'}
                                                                                        />
                                                                                }
                                                                        </div>
                                                                        <div className="p-1 p-md-3">
                                                                            <div className="timestamp">
                                                                                <span>{parse(content_list.info.date.published.split('T')[0])}</span>
                                                                            </div>
                                                                            <div className="card-title">
                                                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    else 
                                                        return null
                                                })
                                            )
                                        })
                                        }
                                        <div className='col-12 text-end mb-3'>
                                            <a className='btn btn-link' href={'./article/all'} >
                                                <i className="bi bi-arrow-left"></i>{language[lang].see_it_all}
                                            </a>
                                        </div> 
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}