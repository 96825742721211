import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET, repo_img_link,*/ content_language, translate } from '../../../../Utils';

import CounterAnimation from '../../CounterAnimation/CounterAnimation';

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeNumbers_01.css"

const HomeNumbers = (props) => {

	const [, set_dados] = useState(null)
	const numbers_list = useRef([])
	const dados_config = useRef([])

    const [numbers_title, set_numbers_title] = useState('')
    const [numbers_subtitle, set_numbers_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    const scrollRef = useRef(null);
    const [scrollTarget, setScrollTarget] = useState(false);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'statistics' && el.info.active) {
                    set_numbers_title(translate(el.info.title, lang))
                    set_numbers_subtitle(translate(el.info.subtitle, lang))

                    let aStatisticsAux = []
                    for (let el of dados_config.current.content[0].statistics) {

                        let jStatistics = {
                            name: translate(el.info.title, lang),
                            value: el.info.value,
                            icon: el.info.icon
                        }

                        aStatisticsAux.push(jStatistics)

                    }

                    window.addEventListener("scroll", () => {
                        //console.log(window.scrollY);
                        //console.log(scrollRef);
                        setScrollTarget(window.scrollY > ((scrollRef.current.offsetTop - scrollRef.current.offsetHeight) - 250));
                    })

                    set_dados(numbers_list.current = aStatisticsAux)
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homenumbers_01" className='div-component' ref={scrollRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {numbers_title}
                            </h3>
                            {
                                numbers_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {numbers_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                        {
                            numbers_list.current !== undefined && numbers_list.current !== null &&  numbers_list.current.length > 0 ?
                                <>
                                    <div className="col-12" >
                                        <div className="row justify-content-center">
                                            {
                                                numbers_list.current.map((v, k) => {
                                                    if(k < 4 && v.value !== undefined && v.value !== '') {
                                                        return (
                                                            <div className="col-6 col-lg-3 mb-4" key={k}>
                                                                <CounterAnimation
                                                                    iconComponent={v.icon !== undefined ? v.icon.bi : ''}
                                                                    durationValue={2000}
                                                                    initialValue={0}
                                                                    targetValue={v.value !== undefined ? v.value : ''}
                                                                    text={v.name !== undefined ? v.name : ''}
                                                                    scrollTarget={scrollTarget}
                                                                />
                                                            </div>
                                                        )
                                                    } else { 
                                                        return null
                                                    }
                                                })              
                                            }
                                        </div>
                                    </div>
                                </> 
                            :
                                null
                        }
                    </div>
                </div>
            </section>
    )
};
export default HomeNumbers;