import React, { useState, useEffect } from 'react';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, repo_img_link, content_language, translate } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import AssecImage from "../ASSEC_Image/Image"

export default function Routes(props) {
    // const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

	const [lang, set_lang] = useState(localStorage.getItem('lang'))
	const [, set_lang_id] = useState('1')

    useEffect(() => {
        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            if(id !== '') {

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/platform/routes/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {

                                if(res.data[0].activo && res.data[0].visible) {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info]);

    return (isloading ?

		<Loader />
		
		:

        <>
            <Navbar />
            <main className="article">
                {
                content.composicao.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    <div className="div-content col-12 mt-5">
                                        <div className="row">
                                            <div className='col-12 col-sm-8'>
                                                <div className={"div-title"}>
                                                    <h1 className="titulo-primario">{translate(el.descricao.nome, lang)}</h1>
                                                </div>

                                                <div className={"div-image mt-3"}>
                                                    <img src={el['multimedia']['link_images'][0][0]['link']} className="img-fluid" alt="" />
                                                </div>

                                                <div className={"div-abstract mt-5"}>
                                                    <p>{ReactHtmlParser(translate(el.descricao.descricao, lang))}</p>
                                                </div>
                                                
                                                <div className={"div-text mt-5"}>
                                                    {ReactHtmlParser(el.text)}
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4'>

                                                <h4 className="bg-primary rounded-lg text-white w-100 py-2 text-center">Informação</h4>

                                                <p>
                                                    <strong>Extensão:</strong> {el.distancia}
                                                    <br />
                                                    <strong>Dificuldade:</strong> {translate(el.dificuldade, lang)}
                                                    <br />
                                                    <strong>Tipo:</strong> {translate(el.tipo, lang)}
                                                </p>

                                                <div className='bg-light text-center p-3 mb-3'>
                                                    <p>Descarregar trilho</p>
                                                    {
                                                    el['multimedia'] !== undefined && el['multimedia']['link_docs'][0] !== undefined && el['multimedia']['link_docs'][0].length > 0 ? 
                                                        el['multimedia']['link_docs'][0].map((el_f, k_f) => {
                                                            return (
                                                                el_f.tipo === 'trilho' ?
                                                                    <a key={k_f} className='btn btn-primary mb-2 mr-2' href={el_f.link}> {translate(el_f.nome, lang)} </a>
                                                                
                                                                :
                                                                    null
                                                            )
                                                        })
                                                    :
                                                        null
                                                    }
                                                </div>

                                                <div className='bg-light text-center p-3 mb-3'>
                                                    <p>Folheto e Mapa PDF</p>
                                                    {
                                                    el['multimedia'] !== undefined && el['multimedia']['link_docs'][0] !== undefined && el['multimedia']['link_docs'][0].length > 0 ? 
                                                        el['multimedia']['link_docs'][0].map((el_f, k_f) => {
                                                            return (
                                                                el_f.tipo === 'mapa' ?
                                                                    <a key={k_f} className='btn btn-primary mb-2 mr-2' href={el_f.link}> {translate(el_f.nome, lang)} </a>
                                                                
                                                                :
                                                                    null
                                                            )
                                                        })
                                                    :
                                                        null
                                                    }
                                                </div>

                                                <div className='bg-light text-center p-3 mb-5'>
                                                    <p>Ficha Técnica</p>
                                                    {
                                                    el['multimedia'] !== undefined && el['multimedia']['link_docs'][0] !== undefined && el['multimedia']['link_docs'][0].length > 0 ? 
                                                        el['multimedia']['link_docs'][0].map((el_f, k_f) => {
                                                            return (
                                                                el_f.tipo === 'ficha' ?
                                                                    <a key={k_f} className='btn btn-primary mb-2 mr-2' href={el_f.link}> {translate(el_f.nome, lang)} </a>
                                                                
                                                                :
                                                                    null
                                                            )
                                                        })
                                                    :
                                                        null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {
                                    el['multimedia'] !== undefined && el['multimedia']['link_docs'][0] !== undefined && el['multimedia']['link_docs'][0].length > 0 ? 
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-documents">
                                                {
                                                el['multimedia']['link_docs'][0].map((el_f, k_f) => {
                                                    return (
                                                        el_f.tipo === 'doc' ?
                                                            <a key={k_f} className='btn btn-primary mb-2 mr-2' href={el_f.link}> {translate(el_f.nome, lang)} </a>
                                                        
                                                        :
                                                            null
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                    {
                                    el['multimedia'] !== undefined && el['multimedia']['link_images'] !== undefined && el.multimedia.link_images[0].length > 1 ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-gallery">
                                                {
                                                el.multimedia.link_images[0].map((el_img, k_img) => {
                                                    if(k_img > 0) {
                                                        return (
                                                            <div key={k_img} className="col-6 col-sm-3 col-lg-2 p-0">
                                                                <a
                                                                    href={el_img.link}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el_img.link.includes(translate(el_img.nome, lang)) || translate(el_img.nome, lang).includes('webp') || translate(el_img.nome, lang).includes('jpg') || translate(el_img.nome, lang).includes('png')) ? '' : translate(el_img.nome, lang)}
                                                                >
                                                                    <AssecImage
                                                                        linkImage={el_img.link !== undefined ? el_img.link : repo_img_link('nophoto.png')}
                                                                        width="400"
                                                                        height="400"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass='img-fluid'
                                                                        alt={(el_img.link.includes(translate(el_img.nome, lang)) || translate(el_img.nome, lang).includes('webp') || translate(el_img.nome, lang).includes('jpg') || translate(el_img.nome, lang).includes('png')) ? '' : translate(el_img.nome, lang)}
                                                                        title={(el_img.link.includes(translate(el_img.nome, lang)) || translate(el_img.nome, lang).includes('webp') || translate(el_img.nome, lang).includes('jpg') || translate(el_img.nome, lang).includes('png')) ? '' : translate(el_img.nome, lang)}
                                                                    />
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer />
        </>
    )
}