import React, { useState, useEffect } from 'react';

//import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image";

export default function Accordion(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [floating_content, set_floating_content] = useState(false);
    const [multipage_banner, set_multipage_banner] = useState(true);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            //console.log('props.info.state.dados_config', props.info.state.dados_config)
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            if(props.info.state.dados_content.info.floating_content !== undefined && props.info.state.dados_content.info.floating_content !== ''){
                set_floating_content(props.info.state.dados_content.info.floating_content)
            }

            if(props.info.state.dados_content.info.multipage_banner !== undefined && props.info.state.dados_content.info.multipage_banner !== ''){
                set_multipage_banner(props.info.state.dados_content.info.multipage_banner)
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

            return
        }
        
    }, [props]);

    return (isloading ?
        <Loader/>
    :
        <>
            {
                content.info['preview'] === true ? 
                    <Preview />
                : 
                    null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="accordion my-5">
                <div className="container">
                    <div className="row">
                        {
                            multipage_banner ?
                                content.content.map((el, k) => {  
                                    if (k === 0){
                                        return (
                                            <div key={k} className="col-12">
                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                        <div className="div-banner mb-3 mb-md-5"> 
                                                            <a
                                                                href={el['language'][lang_id]['media']['image'][0]['link']}
                                                                data-fancybox="gallery"
                                                                data-caption={(el['language'][lang_id]['media']['image'][0]['name'].includes('webp') || el['language'][lang_id]['media']['image'][0]['name'].includes('jpg') || el['language'][lang_id]['media']['image'][0]['name'].includes('png')) ? '' : el['language'][lang_id]['media']['image'][0]['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                    width="1296"
                                                                    height="720"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid shadow-sm" 
                                                                    alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                />
                                                            </a>
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? 
                                                            <div className="div-banner mb-3 mb-md-5">
                                                                <a
                                                                    href={el['media']['image'][0]['link']}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png')) ? '' : el['media']['image'][0]['name']}
                                                                >
                                                                    <AssecImage 
                                                                        linkImage={el['media']['image'][0]['link']}
                                                                        width="1296"
                                                                        height="720"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid shadow-sm" 
                                                                        alt={el['media']['image'][0]['name']}
                                                                />
                                                                </a>
                                                            </div>
                                                        : 
                                                            null 
                                                }

                                                <div className="div-content">
                                                    <h1 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h1>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={"titulo-secundario mb-3"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            null
                                                    }
                                                    { 
                                                        el.text !== '' && el.text !== '<p></p>\n' ?
                                                            <div className="div-text">
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>

                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                        <div className="div-documents mb-3">
                                                            {
                                                                el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                    return (
                                                                        <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                            {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                        </a>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                            <div className="div-documents mb-3">
                                                                {
                                                                el.media.doc.map((el_doc, k_doc) => {
                                                                    return (
                                                                        <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                            {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                        </a>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        :
                                                            null
                                                }

                                                {
                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                        <div className="div-gallery mb-3">
                                                            <div className="row gy-4">
                                                                {
                                                                    el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                        return (
                                                                            <div key={k_img} className="col-6 col-md-3">
                                                                                <a
                                                                                    href={el_img['link']}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ? '' : el_img['name']}
                                                                                >
                                                                                    <figure className='figure mb-0'>
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {
                                                                                            (el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ?
                                                                                                null 
                                                                                            :
                                                                                                <figcaption className='figure-caption p-3 text-center'>{el_img.name}</figcaption>
                                                                                        }
                                                                                    </figure>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                                            <div className="div-gallery mb-3">
                                                                <div className="row gy-4">
                                                                    {
                                                                    el.media.image.slice(1).map((el_img, k_img) => {
                                                                        return (
                                                                            <div key={k_img} className="col-6 col-md-3">
                                                                                <a
                                                                                    href={el_img['link']}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ? '' : el_img['name']}
                                                                                >
                                                                                    <figure className='figure mb-0'>
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {
                                                                                            (el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ?
                                                                                                null 
                                                                                            :
                                                                                                <figcaption className='figure-caption p-3 text-center'>{el_img.name}</figcaption>
                                                                                        }
                                                                                    </figure>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                }
                                            </div>
                                        )
                                    }
                                    return null
                                })
                            :
                                <div className="col-12 title-properties">
                                    <h3 className="titulo-pp">
                                        {(lang === 'pt' || content.info.language === undefined ? content.info.name : (content.info.language[lang_id] === undefined || content.info.language[lang_id].name === undefined || content.info.language[lang_id].name === '' ? content.info.name : content.info.language[lang_id].name))}
                                    </h3>
                                    <hr></hr>
                                </div>
                        }
                        <div className="col-12 content-accordion mt-3">
                            <div className="accordion accordion-flush" id="accordionContent" role="tablist">
                                {
                                    content.content.slice(multipage_banner ? 1 : 0).map((el, k) => {
                                        return (
                                            <div className="accordion-item mb-2" key={k}>
                                                <div className="accordion-header" id={"heading-" + k}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + k} /*aria-expanded="false"*/ aria-expanded={!multipage_banner && k === 0 ? "true" : "false"} aria-controls={"collapse-" + k}>
                                                        <span className='accordion-title'>
                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                        </span>
                                                        { 
                                                            el.subtitle !== '' ?
                                                                <span className={"subtitulo-secundario"}>
                                                                    &#45;&nbsp;{lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                </span>
                                                            :
                                                                ''
                                                        }
                                                        {/*<i className="bi bi-chevron-down" />*/}
                                                    </button>
                                                </div>

                                                <div id={"collapse-" + k} className={"accordion-collapse collapse" + (!multipage_banner && k === 0 ? " show" : "")} aria-labelledby={"heading-" + k} data-bs-parent="#accordionContent">
                                                    <div className="accordion-body">
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                { 
                                                                    floating_content ?
                                                                        <>
                                                                            {
                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                                    <div className="div-image">
                                                                                        <a
                                                                                            href={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                            data-fancybox={"gallery_" + k}
                                                                                            data-caption={(el['language'][lang_id]['media']['image'][0]['name'].includes('webp') || el['language'][lang_id]['media']['image'][0]['name'].includes('jpg') || el['language'][lang_id]['media']['image'][0]['name'].includes('png')) ? '' : el['language'][lang_id]['media']['image'][0]['name']}
                                                                                        >
                                                                                            <AssecImage 
                                                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                                width="450"
                                                                                                height="300"
                                                                                                fit='crop'
                                                                                                type='img'
                                                                                                sClass="img-fluid shadow-sm" 
                                                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                                            />
                                                                                        </a>
                                                                                        
                                                                                        {
                                                                                            el['language'][lang_id]['media']['image'].map((el_gal, k_gal) => {
                                                                                                if(k_gal === 0) {
                                                                                                    return null
                                                                                                }
                                                                                                else {
                                                                                                    return (
                                                                                                        <a
                                                                                                            key={k_gal}
                                                                                                            href={el_gal['link']}
                                                                                                            data-fancybox={"gallery_" + k}
                                                                                                            data-caption={(el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                                                        >
                                                                                                            <AssecImage 
                                                                                                                linkImage={el_gal['link']}
                                                                                                                width="450"
                                                                                                                height="300"
                                                                                                                fit='crop'
                                                                                                                type='img'
                                                                                                                sClass="img-fluid shadow-sm d-none" 
                                                                                                                alt={el_gal['name']}
                                                                                                            />
                                                                                                        </a>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                :
                                                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                                                        <div className="div-image">
                                                                                            <a
                                                                                                href={el['media']['image'][0]['link']}
                                                                                                data-fancybox={"gallery_" + k}
                                                                                                data-caption={(el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png')) ? '' : el['media']['image'][0]['name']}
                                                                                            >
                                                                                                <AssecImage 
                                                                                                    linkImage={el['media']['image'][0]['link']}
                                                                                                    width="450"
                                                                                                    height="300"
                                                                                                    fit='crop'
                                                                                                    type='img'
                                                                                                    sClass="img-fluid shadow-sm" 
                                                                                                    alt={el['media']['image'][0]['name']}
                                                                                                />
                                                                                            </a>
                                                                                        
                                                                                            {
                                                                                                el['media']['image'].map((el_gal, k_gal) => {
                                                                                                    if(k_gal === 0) {
                                                                                                        return null
                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <a
                                                                                                                key={k_gal}
                                                                                                                href={el_gal['link']}
                                                                                                                data-fancybox={"gallery_" + k}
                                                                                                                data-caption={(el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                                                            >
                                                                                                                <AssecImage 
                                                                                                                    linkImage={el_gal['link']}
                                                                                                                    width="450"
                                                                                                                    height="300"
                                                                                                                    fit='crop'
                                                                                                                    type='img'
                                                                                                                    sClass="img-fluid shadow-sm d-none" 
                                                                                                                    alt={el_gal['name']}
                                                                                                                />
                                                                                                            </a>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    :
                                                                                        null
                                                                            }
                                                                            <div className='div-text'>
                                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                            </div>
                                                                            {
                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                                                    <div className="div-documents">
                                                                                        {
                                                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                                                return (
                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                    </a>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                :
                                                                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                                                        <div className="div-documents">
                                                                                            {
                                                                                            el.media.doc.map((el_doc, k_doc) => {
                                                                                                return (
                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                    </a>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                        </div>
                                                                                    :
                                                                                        null
                                                                            }
                                                                        </>
                                                                    :
                                                                        <div className='row gy-4'>
                                                                            {
                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el.language[lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                                    <>
                                                                                        <div className="col-12 col-md-8 col-lg-9 col-content order-1 order-md-0">
                                                                                            <div className='div-text'>
                                                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                                            </div>
                                                                                            {
                                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                                                                    <div className="div-documents">
                                                                                                        {
                                                                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                                                                return (
                                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                    </a>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                :
                                                                                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                                                                        <div className="div-documents">
                                                                                                            {
                                                                                                            el.media.doc.map((el_doc, k_doc) => {
                                                                                                                return (
                                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                    </a>
                                                                                                                )
                                                                                                            })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-12 col-md-4 col-lg-3 col-image order-0 order-md-1" >
                                                                                            <a
                                                                                                href={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                                data-fancybox={"gallery_" + k}
                                                                                                data-caption={(el['language'][lang_id]['media']['image'][0]['name'].includes('webp') || el['language'][lang_id]['media']['image'][0]['name'].includes('jpg') || el['language'][lang_id]['media']['image'][0]['name'].includes('png')) ? '' : el['language'][lang_id]['media']['image'][0]['name']}
                                                                                            >
                                                                                                <AssecImage 
                                                                                                    linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                                    width="450"
                                                                                                    height="300"
                                                                                                    fit='crop'
                                                                                                    type='img'
                                                                                                    sClass="img-fluid shadow-sm" 
                                                                                                    alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                                                />
                                                                                            </a>
                                                                                            
                                                                                            {
                                                                                                el['language'][lang_id]['media']['image'].map((el_gal, k_gal) => {
                                                                                                    if(k_gal === 0) {
                                                                                                        return null
                                                                                                    }
                                                                                                    else {
                                                                                                        return (
                                                                                                            <a
                                                                                                                key={k_gal}
                                                                                                                href={el_gal['link']}
                                                                                                                data-fancybox={"gallery_" + k}
                                                                                                                data-caption={(el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                                                            >
                                                                                                                <AssecImage 
                                                                                                                    linkImage={el_gal['link']}
                                                                                                                    width="450"
                                                                                                                    height="300"
                                                                                                                    fit='crop'
                                                                                                                    type='img'
                                                                                                                    sClass="img-fluid shadow-sm d-none" 
                                                                                                                    alt={el_gal['name']}
                                                                                                                />
                                                                                                            </a>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                :
                                                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                                                        <>
                                                                                            <div className="col-12 col-md-8 col-lg-9 col-content order-1 order-md-0">
                                                                                                <div className='div-text'>
                                                                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                                                </div>
                                                                                                {
                                                                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                                                                        <div className="div-documents">
                                                                                                            {
                                                                                                                el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                                                                    return (
                                                                                                                        <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                            {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                        </a>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                                                                            <div className="div-documents">
                                                                                                                {
                                                                                                                el.media.doc.map((el_doc, k_doc) => {
                                                                                                                    return (
                                                                                                                        <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                            {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                        </a>
                                                                                                                    )
                                                                                                                })
                                                                                                                }
                                                                                                            </div>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-12 col-md-4 col-lg-3 col-image order-0 order-md-1">
                                                                                                <a
                                                                                                    href={el['media']['image'][0]['link']}
                                                                                                    data-fancybox={"gallery_" + k}
                                                                                                    data-caption={(el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png')) ? '' : el['media']['image'][0]['name']}
                                                                                                >
                                                                                                    <AssecImage 
                                                                                                        linkImage={el['media']['image'][0]['link']}
                                                                                                        width="450"
                                                                                                        height="300"
                                                                                                        fit='crop'
                                                                                                        type='img'
                                                                                                        sClass="img-fluid shadow-sm" 
                                                                                                        alt={el['media']['image'][0]['name']}
                                                                                                    />
                                                                                                </a>
                                                                                            
                                                                                                {
                                                                                                    el['media']['image'].map((el_gal, k_gal) => {
                                                                                                        if(k_gal === 0) {
                                                                                                            return null
                                                                                                        }
                                                                                                        else {
                                                                                                            return (
                                                                                                                <a
                                                                                                                    key={k_gal}
                                                                                                                    href={el_gal['link']}
                                                                                                                    data-fancybox={"gallery_" + k}
                                                                                                                    data-caption={(el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                                                                >
                                                                                                                    <AssecImage 
                                                                                                                        linkImage={el_gal['link']}
                                                                                                                        width="450"
                                                                                                                        height="300"
                                                                                                                        fit='crop'
                                                                                                                        type='img'
                                                                                                                        sClass="img-fluid shadow-sm d-none" 
                                                                                                                        alt={el_gal['name']}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    :
                                                                                        <div className="col-12 col-content">
                                                                                            <div className='div-text'>
                                                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                                            </div>
                                                                                            {
                                                                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                                                                    <div className="div-documents">
                                                                                                        {
                                                                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                                                                return (
                                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                    </a>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                :
                                                                                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                                                                        <div className="div-documents">
                                                                                                            {
                                                                                                            el.media.doc.map((el_doc, k_doc) => {
                                                                                                                return (
                                                                                                                    <a key={k_doc} href={el_doc.link} className="btn btn-primary mb-2 me-2" rel="noreferrer" target="_blank">
                                                                                                                        {el_doc.name}<i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                                                                    </a>
                                                                                                                )
                                                                                                            })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}