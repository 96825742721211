import React, { useState, useEffect } from 'react';

import "../Pagina.css";

const CounterAnimation = ({
    iconComponent,
    durationValue,
    initialValue,
    targetValue,
    text,
    scrollTarget,
  }) => {
    const [count, setCount] = useState(initialValue);
    //const duration = 4000; // 4 seconds

    useEffect(() => {
        if(scrollTarget){
            let startValue = initialValue;
            const interval = Math.floor(
            durationValue / (targetValue - initialValue));

            const counter = setInterval(() => {
                startValue += 1;
                setCount(startValue);
                if (startValue >= targetValue) {
                    clearInterval(counter);
                }
            }, interval);

            return () => {
                clearInterval(counter);
                //setCount(initialValue);
            };
        }

    }, [durationValue, targetValue, initialValue, scrollTarget]);
    return (
        <div className="card border-0 shadow h-100">
            <div className="card-body">
                {
                    iconComponent !== '' ?
                        <div className="statistics-icon">
                            <i className={"bi bi-" + (iconComponent)}></i>
                        </div>
                    :
                    null
                }
                <div>
                    <div className="statistics-number">{count}</div>
                    <div className="card-text statistics-label">{text}</div>
                </div>
            </div>
        </div>
    );
};
export default CounterAnimation;