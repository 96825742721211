import React, { useState, useEffect, useRef } from 'react'

// import Loader from '../../../loader';

import HomeMote from "../HomeMote/HomeMote_00";
import HomeNews from "../HomeNews/HomeNews_00";
import HomeProducts from "../HomeProducts/HomeProducts_00";
import HomeServices from "../HomeServices/HomeServices_00";
import HomePartners from "../HomePartners/HomePartners_00";
import HomeGallery from "../HomeGallery/HomeGallery_00";
import HomeBrands from "../HomeBrands/HomeBrands_00";
import HomeNumbers from "../HomeNumbers/HomeNumbers_00";

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const layout = useRef('')
	const content = useRef({})
	const component = useRef({})
	const pw = useRef({})
	const tag_page = useRef({})

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(content.current = props.dados_config)
            set_dados(component.current = props.dados_component)
            set_dados(pw.current = props.pag_welcome)
            set_dados(layout.current = props.layout)
            set_dados(tag_page.current = props.tag_page)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		null
    :
        (() => {

            switch (component.current.info.tag) {
                case 'brand':
                    return (
                        <HomeBrands pag_welcome={true} dados_config={dados_config.current} layout={'home-brands-' + tag_page.current} />
                    )
                case 'gallery':
                    return (
                        <HomeGallery pag_welcome={true} dados_config={dados_config.current} layout={'home-gallery-' + tag_page.current} />
                    )
                case 'mote':
                    return (
                        <HomeMote pag_welcome={true} dados_config={dados_config.current} layout={'home-mote-' + tag_page.current} />
                    )
                case 'news':
                    return (
                        <HomeNews pag_welcome={true} dados_config={dados_config.current} layout={'home-news-' + tag_page.current} />
                    )
                case 'statistics':
                    return (
                        <HomeNumbers pag_welcome={true} dados_config={dados_config.current} layout={'home-numbers-' + tag_page.current} />
                    )
                case 'partner':
                    return (
                        <HomePartners pag_welcome={true} dados_config={dados_config.current} layout={'home-partners-' + tag_page.current} />
                    )
                case 'product':
                    return (
                        <HomeProducts pag_welcome={true} dados_config={dados_config.current} layout={'home-products-' + tag_page.current} />
                    )
                case 'service':
                    return (
                        <HomeServices pag_welcome={true} dados_config={dados_config.current} layout={'home-services-' + tag_page.current} />
                    )

                default:
                    return null
            }


        })()

    )
}
