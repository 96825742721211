import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { nl2br } from 'react-js-nl2br';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import { Helmet } from 'react-helmet';

import { repo_img_link, content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer_00';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

import { language } from '../WEBContent/Language';

export default function Animation(props) {
    // const { pathname } = useLocation();

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, ] = useState(data_interface);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [link, ] = useState('')

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
			
            setLoading(false)
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
    :
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb />
            <main className="article">

                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12 bg-light py-5'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h3 className="titulo-primario">
                                            <span>{content.content[0].title}</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    <div className="div-content col-12 mt-5">
                                        <div className="row">
                                            <div className='col-8 col-sm-8'>
                                                <div className='row'>

                                                    <div className='col-12 col-sm-4'>
                                                        <div className="card bg-rotas rounded-lg text-white w-100 border-0">
                                                            <div className="card-header border-white text-center">
                                                                {new Date(el.date.begin).toLocaleDateString([], { year: 'numeric' })}
                                                            </div>
                                                            <div className="card-body">
                                                                <h5>
                                                                    {new Date(el.date.begin).toLocaleDateString([], { month: 'long', day: 'numeric' })}
                                                                    {
                                                                    el.date.end !== '' && el.date.end !== el.date.begin ?
                                                                    <>
                                                                        <br />
                                                                        <span className='mr-1 mt-2'>{language[lang].date_to}</span>
                                                                        {new Date(el.date.end).toLocaleDateString([], { month: 'long', day: 'numeric' })}
                                                                    </>
                                                                    :
                                                                        null
                                                                    }
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-8'>

                                                        <div className="card bg-animacao rounded-lg text-white w-100 border-0">
                                                            <div className="card-header border-white text-center">
                                                                {el.date.begin_time.replace(':', 'h')} 
                                                                {
                                                                    el.date.end_time !== '' && el.date.end_time !== el.date.begin_time ?
                                                                    <>
                                                                        <span className='mr-1 mt-2'>{language[lang].hour_to}</span>
                                                                        {el.date.end_time.replace(':', 'h')}
                                                                    </>
                                                                    :
                                                                        null
                                                                    }
                                                            </div>
                                                            <div className="card-body">
                                                                <h5>
                                                                    {nl2br(el.contacts.morada)}
                                                                </h5>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='col-12 mt-5'>
                                                        <div className={"div-text py-3 py-md-3"}>
                                                            <p style={{display: 'inline-flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
                                                                <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                    <i className="bi bi-globe mr-2"></i> 
                                                                    <a href={el.contacts.website.includes('http') ? el.contacts.website : 'http://' + el.contacts.website} alt="" target="_blank" rel="noreferrer">
                                                                        {
                                                                        link !== undefined ? link : null
                                                                        //el.contacts.website.includes(el.contacts.website_type) ? el.contacts.website : el.contacts.website_type + el.contacts.website
                                                                        }
                                                                    </a>
                                                                </span>
                                                                {
                                                                el.contacts.email !== '' ?
                                                                    <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-envelope mr-2"></i>

                                                                    <a href={'mailto: '+ el.contacts.email} alt="" target="_blank" rel="noreferrer">{el.contacts.email}</a>


                                                                    </span>
                                                                :
                                                                    null
                                                                } 
                                                                {
                                                                content.info.category !== '' ?
                                                                    <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}} className='d-none'><strong>{content.info.category}</strong></span>
                                                                :
                                                                    null
                                                                }
                                                                {
                                                                el.contacts.tlf !== '' ?
                                                                    <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-telephone mr-2"></i> <a href={"tel:" + el.contacts.tlf}>{el.contacts.tlf}</a></span>
                                                                :
                                                                    null
                                                                }
                                                                {
                                                                el.contacts.tlm !== '' ?
                                                                    <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-phone mr-2"></i> <a href={"tel: " + el.contacts.tlm}>{el.contacts.tlm}</a> <br /></span>
                                                                :
                                                                    null
                                                                }
                                                            </p>
                                                            <h4 className='mt-3'>
                                                                {
                                                                el.value !== '' ?
                                                                    <span className="bg-rotas text-center text-white p-2">{el.value}</span>
                                                                :
                                                                    null
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-4'>

                                                <div className={"div-image"}>
                                                    <AssecImage 
                                                        linkImage={el['media']['image'][0]['link']}
                                                        width="400"
                                                        height="300"
                                                        fit='fit'
                                                        type='img'
                                                        sClass="img-fluid shadow-lg py-3 px-5" 
                                                        alt={el['media']['image'][0]['name']}
                                                    />
                                                    {/*<img src={el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : null} className="img-fluid" alt="" />*/}
                                                </div>
                                            </div>

                                            <div className='col-12 my-5'>

                                                <div className={"div-abstract col-12 mt-3"}>
                                                    <p>{ReactHtmlParser(el.abstract)}</p>
                                                </div>

                                                <div className="div-categories d-none w-100"><i className="bi bi-tag" />
                                                    {
                                                    content.info.tags.map((v, k) => {
                                                        return (
                                                            <span key={k} className="pill-tag">{v.text}</span>
                                                        )
                                                    })
                                                    }
                                                </div>

                                                <div className={"div-text col-12 mt-3"}>
                                                    {ReactHtmlParser(el.text)}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {
                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-documents">
                                                {
                                                el.media.doc.map((el_doc, k_doc) => {
                                                    return (
                                                        <div key={k_doc} className="mb-2">
                                                            <img className="img-fluid" src={el_doc.link} alt="" />
                                                            <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                    {
                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                        <div className="div-content col-12 mb-5">
                                            <div className="row div-gallery">
                                                {
                                                el.media.image.map((el_img, k_img) => {
                                                    if(k_img > 0) {
                                                        return (
                                                            <div key={k_img} className="col-6 col-sm-3 col-lg-2 p-0">
                                                                <a
                                                                    href={el_img.link}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                >
                                                                    <AssecImage
                                                                        linkImage={el_img.link !== undefined ? el_img.link : repo_img_link('nophoto.png')}
                                                                        width="400"
                                                                        height="400"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass='img-fluid'
                                                                        alt={el_img.name}
                                                                        title={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                    />
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        <></>
                                    }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}