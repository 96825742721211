import React, { useState, useEffect, useRef } from 'react'

import Loader from '../../../loader';

import Footer01 from './Footer_01';
import Footer02 from './Footer_02';

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const footer = useRef({})
	const content = useRef({})
	const pw = useRef({})

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }
    const navbar = useRef(data_interface)

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
            set_dados(navbar.current = props.dados_navbar)
        }

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info?.template)
            set_dados(footer.current = props.dados_config.info?.footer)
            set_dados(content.current = props.dados_config)
            set_dados(pw.current = props.pag_welcome)

            // console.log('props.dados_config.info?.footer', props.dados_config.info?.footer)
            // console.log('footer?.current?.info?.tag', footer?.current?.info?.tag)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		<Loader />
    :
        <>
            {
            footer?.current?.info?.tag === 'footer-01' ?
                <Footer01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            :
            footer?.current?.info?.tag === 'footer-02' ?
                <Footer02 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            :
                <Footer01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            }
        </>
    )
}
