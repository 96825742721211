import React, { useState, useEffect, useRef } from 'react';
import {Button } from "reactstrap";

import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer";


import './Forms.css';
import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import {
    set_language,
    reqGET,
	//reqPOST,
    //repo_banner_link,
    content_language,
    functionSendMail

} from '../../../Utils';


import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2';

const ContactForm = () => {


    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    const [data, setData] = useState([]);   

    let getInfo = async () => {

        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/91`)
            .then(res => { 
                setData(res.data)
                //console.log('CONTACTOS', res.data)

            })
            .catch(erro => alert('Erro4'))  

    }    
    useEffect(() => { 
        getInfo()
        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }
    
    }, []); 

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
      }

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            name: '',
            email: '',
            subject: '',
            message: '',
            rgpd: false,    
        }

    }

    const [ , set_dados ] = useState( null ) ;
    const dados = useRef( data_interface ) ;

    const [counterText, setCounterText] = useState('');



    const handle_change_info = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_textarea = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        setCounterText(e.target.value)

        set_dados(dados.current.info[name] = value)
        //set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }
    
   /*const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;
        
        reqPOST(`${Hosts.SIMCore_API}/entities`, dados.current)
            .then(() => {   
                alert('Registo adicionado!')
                //props.history.push(endpoints.list) 
            })
            .catch(() => alert('Problema ao atualizar Registo!'))
    }*/


    /*let mailOptions = {
        from: Hosts.contactFrom,
        // vários emails separados por virgulas
        to: dados.current.info.email,
        //to: 'suporte@assec.pt',

        //cc-> opcional
        cc: Hosts.contactFrom,
        subject: Hosts.webSiteTitle + ' - ' + language[lang].contact_form,
        //text: 'Teste de EMAIL ', -> opcional
        html:
            '<html><head></head><body style="background-color:#F7F7F7;">' +
                '<div style="width:100% !important;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;color:#425968;background-color:#F7F7F7;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:16px;">' +
                    '<table border="0" align="center" cellpadding="0" cellspacing="0" style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-collapse:collapse;">' +
                        '<tr style="border-collapse: collapse;">' +
                            '<td style="border-collapse: collapse;">' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" class="logo" style="padding:0; border-collapse:collapse;">' +
                                            '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" rel="noreferrer">' +
                                                '<img style="width: 600px; height: 90px;" width="600" height="90" src="' + repo_banner_link("banner-email.jpg") + '"/>' +
                                            '</a>'+
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:#ffffff;border:1px solid transparent;border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" class style="padding:0 0 10px; border-collapse:collapse;">' +
                                            '<div style="width:540px; margin: 0 auto; padding: 15px 30px;">'+
                                                '<h4 style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;">'+ language[lang].contact_form +':</h4>'+
                                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                                                '<br/>'+
                                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                                                '<br/>'+
                                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                                                '<br/>'+
                                                '<br/>' +
                                                '<span style="font-family:Arial,Helvetica,sans-serif;font-size:14px;line-height:18px;"><b>'+ language[lang].message +': </b><label>' + dados.current.info.message + '</label></span>' +
                                                '<br/>'+
                                            '</div>'+
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                                '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; background-color:' + Hosts.FOOTER_EMAIL_COLOR +'; padding: 10px; border:1px solid transparent;border-collapse:collapse;">' +
                                    '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" style="padding:10px 0px; border-collapse:collapse;">' +
                                            '<div style="margin: 0 auto; width:600px;">'+
                                                '<div style="text-align: center; font-size: small; color: #FFFFFF;">'+
                                                    '<span style="text-transform: uppercase;font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteTitle + '</span>'+
                                                    '<br/>'+
                                                    '<span style="font-family:Arial,Helvetica,sans-serif;font-size:12px;">' + Hosts.webSiteAdress + '</span>'+
                                                    '<br/>'+
                                                    '<a alt="' + Hosts.webSiteTitle + '" title="' + Hosts.webSiteTitle + '" href="' + Hosts.EMAIL_WEB_SITE_URL + '" target="_blank" rel="noreferrer" style="font-family:Arial,Helvetica,sans-serif;text-decoration:none;"> '+
                                                        '<span style="color: #FFFFFF;font-family:Arial,Helvetica,sans-serif;font-size:12px;" >'+ Hosts.EMAIL_WEB_SITE_URL +'</span>' +
                                                    '</a>'+
                                                '</div>'+
                                            '</div>' +
                                        '</td>' +
                                    '</tr>' +
                                '</table>' +
                            '</td>' +
                        '</tr>' +
                    '</table>' +
                '</div>'+
            '<body><html>',

    };*/

    const handleSubmit = async e => {
        e.preventDefault();

        //alert('SUBMIT');
        //return;

        let mail_content =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
        '<tr style="border-collapse: collapse;">' +
            '<td align="left" class style="padding:40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                '<div>'+
                    '<h4>'+ language[lang].contact_form +':</h4>'+
                    '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                    '<br/>'+
                    '<span"><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                    '<br/>'+
                    '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                    '<br/>'+
                    '<br/>' +
                    '<span><b>'+ language[lang].message +': </b><label>' + dados.current.info.message + '</label></span>' +
                    '<br/>'+
                '</div>'+
            '</td>' +
        '</tr>' +
    '</table>'

    functionSendMail(Hosts.contactFrom, [dados.current.info.email], [Hosts.contactFrom], Hosts.webSiteTitle + ' - ' + language[lang].contact_form, mail_content, [])
        .then((res) => {
            if(res === true) {
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                document.getElementById('agree').checked = false
                setAgree(false)
                //setRefresh(refresh + 1)
                Swal.fire({
                    title: language[lang].contact_form,
                    text: language[lang].sent_successfully,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                    })
            }
            else {
                console.log('erro')
            }
        })


        /*reqPOST(`${Hosts.SIMCore_API_MAIL}`, {data: mailOptions})
            .then(() => { 
                //console.log('Notificações enviadas com sucesso') 
                set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                document.getElementById('agree').checked = false
                //setAgree(false)
                //setRefresh(refresh + 1)
                Swal.fire({
                    title: language[lang].contact_form,
                    text: language[lang].sent_successfully,
                    icon: 'success',
                    //showCancelButton: true,
                    confirmButtonColor: '#08396a',
                    //confirmButtonText: 'Tentar novamente',
                  })
        
            })
            .catch((error) => console.log(error)//alert('Problema ao enviar notificações!'))*/

    }

        
    return(
        <div>
            <Navbar />
            {/*<Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />*/}
            <section className="margemBody">

                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12 bg-light py-5'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h3 className="titulo-primario">
                                            <span>
                                                {language[lang].contacts}
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-4">
                    <form onSubmit={handleSubmit}>

                        <div className="row">
                            <div className="col-12 col-md-6">
                                {
                                    data.map((v, key) => {
                                        return (
                                        <div key={key} className='row'>
                                            <div className="col-12 my-3">
                                                <p className="subtitulo-secundario">
                                                {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                </p>
                                            </div>
                                            <div className='col-12'>
                                                {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' || v.content[0].language[lang_id].text === '<p></p>\n' ? v.content[0].text : v.content[0].language[lang_id].text))}
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-12 col-md-6 mt-5 mt-md-0">
                                <div className="row d-none">
                                    <div className="col-12">
                                        <p>
                                            {language[lang].read_terms_conditions}
                                            {/*Leia os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeter o formulário. Ao submeter concorda com os termos do nosso serviço.*/}
                                        </p>
                                    </div>
                                    
                                    <div className="col-12 col-sm-6">
                                        <label>{language[lang].name}*</label>
                                        <input type="text" name="name" value={dados.current.info.name} className="form-control" placeholder="" onChange={handle_change_info} required />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label>{language[lang].email}*</label>
                                        <input type="email" name="email" 
                                            value={dados.current.info.email} 
                                            className="form-control" 
                                            placeholder="" 
                                            onChange={handle_change_info} required
                                        /> 
                                    </div>
                                    
                                    <div className="col-12 mt-2">
                                        <label>{language[lang].subject}*</label>
                                        <input type="subject" name="subject" 
                                            value={dados.current.info.subject} 
                                            className="form-control" 
                                            placeholder="" 
                                            onChange={handle_change_info} required
                                        /> 
                                    </div>  
                                    <div className="col-12 mt-2">
                                        <label>{language[lang].message}*</label>
                                        <textarea
                                            style={{ height: "180px", width: "100%" }}
                                            id="message"
                                            name="message"
                                            maxLength="600"
                                            value={dados.current.info.message}
                                            onChange={handle_change_info_textarea}
                                            required
                                        />
                                        <p className="text-right my-2 font-weight-bold">{language[lang].maximum_numbers_of_caracters}&#58;&nbsp;{counterText.length}/600</p>
                                        {/* <TextareaCounter countLimit={200} style={{height: "100px", width: "100%"}} id="message" name="message" value={dados.current.info.message} onChange={handle_change_info} required/>
                                        <input type="text" name="message" value={dados.current.info.message} className="form-control" placeholder="" onChange={handle_change_info} /> */}
                                    </div>

                                    <div className="col-12">
                                        <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                        <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
                                        <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                    </div>
                                    <div className='col-12 text-right'>
                                        {/*{console.log('AGREE2', agree)}
                                        {console.log('MAIL: ', JSON.stringify(mailOptions))}*/}
                                        <Button disabled={!agree} className="mt-5 mx-auto" color="primary">{language[lang].send}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe width="100%" height="500" id="gmap_canvas" title="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1106.8029455334727!2d-7.55218508381398!3d40.310306612348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3d269e9509f6f5%3A0x14da44393520ae06!2sPousada%20de%20Juventude%20Serra%20da%20Estrela!5e1!3m2!1spt-PT!2spt!4v1715242177259!5m2!1spt-PT!2spt" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </section>
            <Footer />
        </div>
    );


};

export default ContactForm;