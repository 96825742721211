import React, { useState, useEffect } from 'react';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf/*, faAngleDown*/ } from "@fortawesome/free-solid-svg-icons";

import AssecImage from "../ASSEC_Image/Image"

export default function Card(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        
        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="div-card my-3 mb-lg-5">
                <section className="content-body">

                    <div className="container">
                        {
                            content.content.map((el, k) => {  
                                if (k === 0){
                                    return (
                                        <div key={k} className="row">
                                            {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                <div className="col-12 div-image"> 
                                                    {/*<a
                                                        href={el['language'][lang_id]['media']['image'][0]['link']}
                                                        data-fancybox="gallery"
                                                        data-caption={el['language'][lang_id]['media']['image'][0]['link'].includes(el['language'][lang_id]['media']['image'][0]['name']) ? '' : el['language'][lang_id]['media']['image'][0]['name']}
                                                    ></a>*/}
                                                    <AssecImage 
                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                        width="1280"
                                                        height="720"
                                                        fit='crop'
                                                        type='img'
                                                        sClass="img-fluid rounded shadow-sm" 
                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                    />
    
                                                    {
                                                        el['language'][lang_id]['media']['image'].map((el_gal, k_gal) => {
                                                            if(k_gal === 0) {
                                                                return null
                                                            }
                                                            else {
                                                                return (
                                                                    <a
                                                                        key={k_gal}
                                                                        href={el_gal['link']}
                                                                        data-fancybox="gallery"
                                                                        data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                    >
                                                                        <AssecImage 
                                                                            linkImage={el_gal['link']}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid rounded shadow-sm d-none" 
                                                                            alt={el_gal['name']}
                                                                        />
                                                                    </a>
                                                                )
                                                            }
                                                        })
                                                    }
    
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? 
                                                    <div className="col-12 div-image">
                                                        {/*<a
                                                            href={el['media']['image'][0]['link']}
                                                            data-fancybox="gallery"
                                                            data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                        ></a>*/}
                                                        <AssecImage 
                                                            linkImage={el['media']['image'][0]['link']}
                                                            width="1280"
                                                            height="720"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid rounded shadow-sm" 
                                                            alt={el['media']['image'][0]['name']}
                                                        />
    
                                                        {
                                                            el['media']['image'].map((el_gal, k_gal) => {
                                                                if(k_gal === 0) {
                                                                    return null
                                                                }
                                                                else {
                                                                    return (
                                                                        <a
                                                                            key={k_gal}
                                                                            href={el_gal['link']}
                                                                            data-fancybox="gallery"
                                                                            data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el_gal['link']}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid rounded shadow-sm d-none" 
                                                                                alt={el_gal['name']}
                                                                            />
                                                                        </a>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                : 
                                                    null 
                                            }
                                            {
                                                <div className="div-content col-12 mt-3 mt-md-5 mb-3">
    
                                                    <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h2>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={"titulo-secundario mb-3"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            null
                                                    }
                                                    { 
                                                        el.text !== '' ?
                                                            <div className="div-text">
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            }
    
                                            {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 && 0 ?
                                                <div className="col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                            el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-3">
                                                                        <a
                                                                            href={el_img['link']}
                                                                            data-fancybox="gallery"
                                                                            data-caption={(el_img['link'].includes(el_img['name']) || el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ? '' : el_img['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el_img.name}
                                                                            />
                                                                        </a>
                                                                        {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 && 0 ?
                                                    <div className="col-12 mb-5">
                                                        <div className="row div-gallery">
                                                            {
                                                            el.media.image.slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-3">
                                                                        <a
                                                                            href={el_img['link']}
                                                                            data-fancybox="gallery"
                                                                            data-caption={(el_img['link'].includes(el_img['name']) || el_img['name'].includes('webp') || el_img['name'].includes('jpg') || el_img['name'].includes('png')) ? '' : el_img['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el_img.name}
                                                                            />
                                                                        </a>
                                                                        {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                            }
                                            {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                <div className="col-12 div-documents mb-5">
                                                    {
                                                        el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                    
                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        <FontAwesomeIcon className="font-weight-small pe-2" icon={faFilePdf} fixedWidth />
                                                                        {el_doc.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                    <div className="col-12 div-documents mb-5">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    {/*<img className="img-fluid" src={el_doc.link} title="" alt="" />
                                                                    <a className="btn btn-primary" href={el_doc.link} rel="noreferrer" target="_blank">{el_doc.name}</a>*/}
                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        <FontAwesomeIcon className="font-weight-small pe-2" icon={faFilePdf} fixedWidth />
                                                                        {el_doc.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                :
                                                    null
                                            }
                                            
                                        </div>
                                    )
                                }
                                return null
                            })
                        }
                    </div>
                </section>

                <section className="content-body content-list">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 mb-5">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    k > 0 ?
                                        <div key={k} className="col">
                                            <div className="card shadow-sm">
                                                {
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                    <>
                                                        <AssecImage 
                                                            linkImage={el.media.image[0].link}
                                                            width="450"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid" 
                                                            alt=""
                                                        />
                                                        {/* <img alt='' className="img-fluid" src={el.media.image[0].link} /> */}
                                                    </>
                                                :
                                                    ""
                                                }

                                                <div className="card-body">
                                                    <div className="card-text">{ReactHtmlParser(el.text)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        null
                                )
                            })
                            }
                        </div>
                    </div>
                </section>

            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}