import React, { useState, useEffect } from 'react';
// import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';

import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function Insight(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

	//const [lang, ] = useState('pt');
    const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('')

			return
		}
        
    }, [props.info]);

    return (isloading ?
		<Loader />
	:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="gallery-detail my-5">
                {
                    content.content.map((el, k) => { 
                        return (
                            <div id="gallery" key={k} className="container">
                                <div className="row">
                                    <div className="col-12 text-end mb-3">
                                        <a className="btn btn-link" href={'./gallery/all'} >
                                            <i className="bi bi-arrow-left"></i> {language[lang].back}
                                        </a>
                                    </div>
                                    <div className={"col-12 title-properties " + (el.text !== '' && el.text !== '<p></p>\n' ? 'mb-3' : 'mb-5')}>
                                        <h3 className="titulo-primario">
                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                        </h3>
                                    </div>
                                    {
                                        el.text !== '' && el.text !== '<p></p>\n' ?

                                            <div className="col-12 text-center mb-5">
                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                            </div>
                                        : null 
                                    }

                                    {
                                        //com layout masonry
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                            <div className="col-12 mb-5">
                                                    <div className="masonry-layout">
                                                    {
                                                        el.language[lang_id].media.image.map((el_img, k_img) => {

                                                            let link = ''
                                                            let youtube_thumbnail = ''

                                                            if(el_img.type === 'video') {

                                                                link = el_img.link.replace('watch?v=', 'embed/');
                                                            
                                                                youtube_thumbnail = link.split('/').pop();
                                                            }

                                                            return (
                                                                <div key={k_img} className="masonry-item card mb-3">

                                                                        {
                                                                            el_img.type === 'video' ?

                                                                                <a
                                                                                    href={link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                >
                                                                                    <div className="image-container">
                                                                                        <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                        <div className="hover-container">
                                                                                            <div className='icon'>
                                                                                                <i class="bi bi-play-circle-fill"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <div className='image-caption'>{el_img.name}</div>
                                                                                    }
                                                                                </a>

                                                                            :
                                                                                <a
                                                                                    href={el_img.link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                >
                                                                                    <div className="image-container">
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            //fit='crop'
                                                                                            fit=''
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                        <div className="hover-container">
                                                                                            <div className='icon'>
                                                                                                <i class="bi bi-eye-fill"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <div className='image-caption'>{el_img.name}</div>
                                                                                    }
                                                                                </a>
                                                                        }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                <div className="col-12 mb-5">
                                                    <div className="masonry-layout">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = ''
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = el_img.link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="masonry-item card ">

                                                                            {
                                                                                el_img.type === 'video' ?

                                                                                    <a
                                                                                        href={link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                    >
                                                                                        <div className="image-container">
                                                                                            <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                            <div className="hover-container">
                                                                                                <div className='icon'>
                                                                                                    <i className="bi bi-play-circle-fill"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                ''
                                                                                            :
                                                                                                <div className='image-caption'>{el_img.name}</div>
                                                                                        }
                                                                                    </a>

                                                                                :
                                                                                    <a
                                                                                        href={el_img.link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                    >
                                                                                        <div className="image-container">
                                                                                            <AssecImage 
                                                                                                linkImage={el_img.link}
                                                                                                width="450"
                                                                                                height="300"
                                                                                                //fit='crop'
                                                                                                fit=''
                                                                                                type='img'
                                                                                                sClass="img-fluid" 
                                                                                                alt={el_img.name}
                                                                                            />
                                                                                            {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                            <div className="hover-container">
                                                                                                <div className='icon'>
                                                                                                    <i className="bi bi-eye-fill"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                ''
                                                                                            :
                                                                                                <div className='image-caption'>{el_img.name}</div>
                                                                                        }
                                                                                    </a>
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            : null
                                    }

                                    {
                                        //com card-columns (bootstrap 4) (desactivado)
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 && 0 ?
                                            <div className="div-content col-12 card-columns mb-5">
                                                {
                                                    el.language[lang_id].media.image.map((el_img, k_img) => {

                                                        let link = ''
                                                        let youtube_thumbnail = ''

                                                        if(el_img.type === 'video') {

                                                            link = el_img.link.replace('watch?v=', 'embed/');
                                                        
                                                            youtube_thumbnail = link.split('/').pop();
                                                        }

                                                        return (
                                                            <div key={k_img} className="card mb-3">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 && 0 ?
                                                <div className="div-content col-12 card-columns mb-5">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = ''
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = el_img.link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="card mb-3">

                                                                            {
                                                                                el_img.type === 'video' ?

                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>

                                                                                :
                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={el_img.link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <AssecImage 
                                                                                                linkImage={el_img.link}
                                                                                                width="450"
                                                                                                height="300"
                                                                                                fit='crop'
                                                                                                type='img'
                                                                                                sClass="img-fluid" 
                                                                                                alt={el_img.name}
                                                                                            />
                                                                                            {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                </div>
                                            : null
                                    }

                                    { 
                                        //sem card-columns (desactivado)
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 && 0?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-gallery">
                                                    {
                                                        el.language[lang_id].media.image.map((el_img, k_img) => {

                                                            let link = el_img.link
                                                            let youtube_thumbnail = ''

                                                            if(el_img.type === 'video') {

                                                                link = link.replace('watch?v=', 'embed/');
                                                            
                                                                youtube_thumbnail = link.split('/').pop();
                                                            }

                                                            return (
                                                                <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 && 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = el_img.link
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                        {
                                                                            el_img.type === 'video' ?

                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                        {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                    </figure>
                                                                                </a>

                                                                            :
                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={el_img.link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                        {
                                                                                            (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                ''
                                                                                            :
                                                                                                <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                        }
                                                                                    </figure>
                                                                                </a>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}