import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar.css';

import { language } from '../WEBContent/Language';
// import CostumModalLogin from '../GenericStore/CostumModalLogin';

import {
    reqGET,
    set_language,
    repo_site_assets_link, 
    //repo_site_documents_link, 
    content_language
} from '../../../Utils';

import { faLongArrowAltLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Hosts from "../../../Hosts";
// import { translate_generic_store, translate_horas } from '../GenericStore/utils';
// import useContextProvider from '../GenericStore/Providers/ContextProvider';
// import { nome_empresa } from '../GenericStore/HOSTS';
// import { network } from '../GenericStore/UseService';
import { usarToken } from '../GenericStore/UseToken';


const Navbar = (props) => {

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }

    const [navbar, set_navbar] = useState(data_interface);

    const [, set_user_profile] = useState('');
    const [pag_logo, set_pag_logo] = useState('');

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    const [enableFixedSideNavigationMenu, /*setEnableFixedSideNavigationMenu*/] = useState(true);

    const [/*refresh*/, setRefresh] = useState(0);

    const [page_location, set_page_location] = useState(useLocation().pathname.split('/').pop());

    const location = useLocation().pathname;

    const change_lang = (l) => {
        set_language(l)
        set_lang(l)
        window.location.reload()
    }
    useEffect(() => {

        if(props.pag_logo !== undefined && props.pag_logo !== null && props.pag_logo !== ''){
            set_pag_logo(props.pag_logo)
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        if(page_location === 'all') {
            let aux = location.split('/all');
            set_page_location(aux[0].split('/').pop())
        }

        set_navbar({ id: '', info: '', content: { childs: [] } })

        let getInfo = async () => {

            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)

            if(token_info !== undefined && token_info !== null)
                set_user_profile(token_info.user_info.profile)

            await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                .then(res => {
                    res.data.forEach((v) => {
                        if (v.info.principal === true) {

                            if (lang !== 'pt') {
                                let x = 0
                                content_language.forEach((cl) => {
                                    if (cl.code === lang) {

                                        v.content.childs.forEach((el) => {
                                            if (el.id_content !== '') {
                                                if (el.children !== undefined) {
                                                    x = x - el.children.length
                                                    el.children.forEach((el_c) => {
                                                        reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                            .then(res_3 => {
                                                                if (res_3.data.length > 0) {
                                                                    if (res_3.data[0].info.language.length > 0) {
                                                                        if (res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                            if (res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                                el_c.title = res_3.data[0].info.language[cl.id]['name'];

                                                                                x++
                                                                                if (v.content.childs.length === x) {
                                                                                    setRefresh(refresh => refresh + 1)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        x++
                                                                        if (v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if (v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            })
                                                    })
                                                }

                                                reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                    .then(res_2 => {
                                                        if (res_2.data.length > 0) {
                                                            if (res_2.data[0].info.language.length > 0) {
                                                                if (res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                    if (res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                        el.title = res_2.data[0].info.language[cl.id]['name'];

                                                                        x++
                                                                        if (v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                x++
                                                                if (v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if (v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    })


                                            }
                                            else {
                                                if (el['language'] !== undefined && el['language'] !== null) {
                                                    if (el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') {
                                                        el.title = el.language[cl.id]['title']

                                                        x++
                                                        if (v.content.childs.length === x) {
                                                            setRefresh(refresh => refresh + 1)
                                                        }
                                                    }
                                                }
                                            }

                                        })

                                    }
                                })

                            }

                            set_navbar(v);
                        }
                    });

                })
                .catch(erro => console.log('ERROR', erro))
        }

        getInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang])

    return (
        <>
            {
            <div className={"sticky-top"}>
                <section id="navbar">
                    {
                    !enableFixedSideNavigationMenu ?
                        <nav className="navbar navbar-expand-lg bg-navbar py-0 px-0 nav-effect">
                            <div className="col-12">
                                <div className="row">

                                    <div className="col-12 col-lg-10 offset-lg-1 topbar w-100 py-2 small">
                                        <div className="float-left d-none">
                                            <button className={"pr-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                            &#124;
                                            <button className={"pr-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                        </div>
                                        <div className="float-right bg-white">
                                            <button className={"p-2 button_no_border " + (lang === 'pt' ? 'current_lang' : '')} onClick={() => change_lang('pt')}>PT</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'en' ? 'current_lang' : '')} onClick={() => change_lang('en')}>EN</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'es' ? 'current_lang' : '')} onClick={() => change_lang('es')}>ES</button>
                                            &#124;
                                            <button className={"p-2 button_no_border " + (lang === 'fr' ? 'current_lang' : '')} onClick={() => change_lang('fr')}>FR</button>
                                        </div>
                                    </div>

                                    <div className="col-12 d-none">
                                        <div className="row text-center border-bottom">
                                            <a className="navbar-brand pt-0 pb-0 mr-auto ml-auto " href={Hosts.WEB_SITE_URI} alt="">
                                                <img id="big-logo" src={pag_logo} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="my-4 svg-logo img-fluid" />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 d-lg-none d-flex justify-content-between bg-color-white py-2">
                                        <a className="navbar-brand" href={Hosts.WEB_SITE_URI}>
                                            <img id="small-logo" className="svg-logo-small img-fluid" src={pag_logo} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                        </a>
                                        <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    </div>

                                    <div className="col-12 col-lg-10 offset-lg-1 collapse navbar-collapse" id="navbarNav">
                                        <a className="navbar-brand pl-3 d-none d-lg-block py-2" href={Hosts.WEB_SITE_URI} alt="">
                                            <img id="big-logo" className="svg-logo img-fluid" src={pag_logo} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                        </a>
                                        <div className="top w-100 d-none bg-color-gray-light px-4 py-2">
                                            <div className="float-left d-none">
                                                <button className="pr-2 button_no_border" onClick={() => change_lang('en')}>EN</button>
                                                |
                                                <button className="pl-2 button_no_border" onClick={() => change_lang('pt')}>PT</button>
                                            </div>
                                            <div className="text-right">
                                                <a className={"pr-2 " + (page_location === 'form_author' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'form_author'}>{language[lang].regist}</a>
                                                |
                                                <a className="pl-2" href={Hosts.WEB_BACKOFFICE_URI} target="_blank" rel="noreferrer">{language[lang].login}</a>
                                            </div>
                                        </div>
                                        <ul className="navbar-nav ml-auto my-2">
                                            {
                                            props.mostrar_botao_voltar ?
                                                <li className="nav-item">
                                                    <a href={Hosts.WEB_SITE_URI} className={"nav-link "}>
                                                        <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                        {language[lang].back}
                                                    </a>
                                                </li>
                                            :
                                                navbar.content.childs.map((v, key) => {
                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                        :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                                                    //let link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content);
                                                    let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                        return (
                                                            <li className="nav-item dropdown" key={key}>
                                                                <a className="nav-link px-4 dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {
                                                                            let link_content_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                            :
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))

                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ?
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {v2.title}
                                                                                    </a>
                                                                                :
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {v2.title}
                                                                                    </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                                :
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                        )
                                                    }
                                                })
                                            }
                                        </ul>
                                        <div className="overlay"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <ul className="navbar-nav ml-auto my-2">
                                    {
                                    navbar.content.childs.map((v, key) => {
                                        
                                        /* if (v.id === 17) { */
                                            // console.log('v', v)
                                            let link = ''
                                            v.id_content !== '' ?
                                                link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                            :
                                                link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + v.link)

                                                let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : v.id_content);
                                            if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                return (
                                                    <li className="nav-item dropdown" key={key}>
                                                        <a href="true" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                            {v.title}
                                                            <i className="bi bi-chevron-down ml-1" />
                                                        </a>
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                            {
                                                                v.children.map((v2, key2) => {

                                                                    // console.log('v2', v2)

                                                                    let link_dropdown_item = ''
                                                                    v2.id_content !== '' ?
                                                                        link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                    :
                                                                        link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)
                                                                    
                                                                    let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                                                                    if(current_top_dropdown === '') {
                                                                        if(location === '/' + v2.link) {
                                                                            current_top_dropdown = v2.link
                                                                        }
                                                                    }

                                                                    return (
                                                                        v2.link.substr(0, 4) !== 'http' ?
                                                                            <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                            </a>
                                                                        :
                                                                            <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                            </a>
                                                                    )


                                                                })
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            else {
                                                return (
                                                    v.link.substr(0, 4) !== 'http' ?
                                                        <li className="nav-item" key={key} >
                                                            <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                {v.title}
                                                            </a>
                                                        </li>
                                                    :
                                                        <li className="nav-item" key={key} >
                                                            <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                {v.title}
                                                            </a>
                                                        </li>
                                                    
                                                )

                                            }
                                        /* } */
                                        /* return (
                                            <li className="nav-item" key={key}>
                                                <Link activeClassName="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                    className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                    {v.title}
                                                </Link>
                                            </li>
                                        ) */
                                    })
                                    }
                                </ul>
                            </div>
                        </nav>
                    :
                        <nav className="navbar navbar-expand-lg py-0 bg-navbar px-0 nav-effect">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-lg-10 offset-lg-1 w-100 pt-2 pb-0 small">
                                        <div className="float-right">
                                            <button className={"pb-0 button_no_border " + (lang === 'pt' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('pt')}>PT</button>
                                            &#124;
                                            <button className={"pb-0 button_no_border " + (lang === 'en' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('en')}>EN</button>
                                            &#124;
                                            <button className={"pb-0 button_no_border " + (lang === 'es' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('es')}>ES</button>
                                            &#124;
                                            <button className={"pb-0 button_no_border " + (lang === 'fr' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('fr')}>FR</button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-12 col-xl-10 offset-xl-1 text-center py-0">
                                        <a className="navbar-brand pl-2" href={Hosts.WEB_SITE_URI} alt={Hosts.webSiteTitle}>
                                            <img id="big-logo" className="svg-logo img-fluid py-2" /*src={logoPath(mainLogo)}*/ src={pag_logo} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                        </a>
                                        <button className="navbar-toggler align-self-center" type="button" /*onClick={changeLogo}*/ data-toggle="collapse" data-target="#sideNavigationMenu" aria-controls="sideNavigationMenu" aria-expanded="false" aria-label="Toggle navigation">
                                            {/*<span className="navbar-toggler-icon"></span>*/}
                                            <div className="animated-icon3">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="collapse navbar-collapse" id="sideNavigationMenu">

                                        <ul className="navbar-nav mb-3 text-center">

                                            <li className='nav-item'>
                                                <a className="text-uppercase" href="/#" aria-label={lang} aria-expanded="false">
                                                    <img className="img-fluid" src={repo_site_assets_link("faqs.svg")} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                                </a>
                                            </li>
                                        </ul>

                                        <ul className="navbar-nav my-2">
                                            {
                                            props.mostrar_botao_voltar ?
                                                <li className="nav-item" /* key={key} */ >
                                                    <a href={Hosts.WEB_SITE_URI} className={"nav-link"}>
                                                        <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                        {language[lang].back}
                                                    </a>
                                                </li>
                                            :
                                                navbar.content.childs.map((v, key) => {

                                                    // console.log(v)

                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                        :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                                                    let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {

                                                        return (
                                                            <li className="nav-item dropdown" key={key}>
                                                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {

                                                                            //console.log(v2)

                                                                            let link_content_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                                :
                                                                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))


                                                                            //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                            if (location === '/' + v2.link) {
                                                                                current_top_dropdown = v2.link
                                                                            }

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ?
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {v2.title}
                                                                                    </a>
                                                                                    :
                                                                                    <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {v2.title}
                                                                                    </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {

                                                        return (
                                                            v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                                :
                                                                <li className="nav-item" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>

                                                        )

                                                    }
                                                })
                                            }

                                            {/*
                                                <li className="nav-item d-block d-lg-none">
                                                    <a className="nav-link" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                        <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                        {language[lang].meteorology}
                                                    </a>
                                                </li>
                                                <li className="nav-item d-block d-lg-none">
                                                    <a className={"nav-link " + (page_location === 'livecam' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'livecam'}>
                                                        <img src={repo_site_assets_link("livecam.svg")} alt={language[lang].livecam} title={language[lang].livecam} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                        {language[lang].livecam}
                                                    </a>
                                                </li>
                                                <li className="nav-item dropdown d-none">
                                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                        Teste Dropdown
                                                        <FontAwesomeIcon className="mr-2" icon={faAngleDown} fixedWidth />
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <a className="dropdown-item" href="/#">Action</a>
                                                        <a className="dropdown-item" href="/#">Another action</a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="/#">Something else here</a>
                                                    </div>
                                                </li>
                                            */}
                                        </ul>
                                        <ul className="navbar-nav social-media mt-5 text-center">
                                            <li className="nav-item">
                                                {
                                                    Hosts.fbLink !== '' ?
                                                        <a className="nav-link d-inline-block p-0 mr-3" href={Hosts.fbLink} title="Facebook" alt="Facebook" target="_blank" rel="noreferrer">
                                                            <i className="bi bi-facebook"></i>
                                                            {/*<img src={repo_site_assets_link("facebook.svg")} title="Facebook" alt="Facebook" className="img-fluid"/>*/}
                                                        </a>
                                                    : null
                                                }
                                                {
                                                    Hosts.twitterLink !== '' ?
                                                        <a className="nav-link d-inline-block p-0 mr-3" href={Hosts.twitterLink} title="Twitter X" alt="Twitter X" target="_blank" rel="noreferrer">
                                                            <i className="bi bi-twitter-x"></i>
                                                            {/*<img src={repo_site_assets_link("twitter.svg")} title="Twitter" alt="Twitter" className="img-fluid"/>*/}
                                                        </a>
                                                    : null
                                                }
                                                {
                                                    Hosts.instaLink !== '' ?
                                                        <a className="nav-link d-inline-block p-0" href={Hosts.instaLink} title="Instagram" alt="Instagram" target="_blank" rel="noreferrer">
                                                            <i className="bi bi-instagram"></i>
                                                            {/*<img src={repo_site_assets_link("instagram.svg")} title="Instagram" alt="Instagram" className="img-fluid"/>*/}
                                                        </a>
                                                    : null
                                                }
                                            </li>
                                        </ul>
                                        <div className="overlay d-none"></div>
                                    </div>

                                    <div className='col-12 bg-primary collapse navbar-collapse' id="navbarNav">
                                        <ul className="navbar-nav mx-auto my-2 text-uppercase">
                                            {
                                            navbar.content.childs.map((v, key) => {
                                                
                                                /* if (v.id === 17) { */
                                                    // console.log('v', v)
                                                    let link = ''
                                                    v.id_content !== '' ?
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                    :
                                                        link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + v.link)

                                                    let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : v.id_content);
                                                    if(current_top_check === '' && link.includes('/all')) {
                                                        let link_aux = link.split('/all')
                                                        current_top_check = link_aux[0].split('/')
                                                        current_top_check = current_top_check.pop()
                                                    }

                                                    if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                        return (
                                                            <li key={key} className="nav-item dropdown">
                                                                <a href="true" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    {v.title}
                                                                    <i className="bi bi-chevron-down ml-1" />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                    {
                                                                        v.children.map((v2, key2) => {

                                                                            // console.log('v2', v2)

                                                                            let link_dropdown_item = ''
                                                                            v2.id_content !== '' ?
                                                                                link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                            :
                                                                                link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)
                                                                            
                                                                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                                                                            if(current_top_dropdown === '') {
                                                                                if(location === '/' + v2.link) {
                                                                                    current_top_dropdown = v2.link
                                                                                }
                                                                            }

                                                                            return (
                                                                                v2.link.substr(0, 4) !== 'http' ?
                                                                                    <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                        {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                    </a>
                                                                                :
                                                                                    <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                        {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                    </a>
                                                                            )

                                                                        })
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            v.link.substr(0, 4) !== 'http' ?
                                                                <li className="nav-item mx-2" data-a={page_location + ' ' + current_top_check} key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location !== '' && page_location === current_top_check ? 'current_top' : '')} >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            :
                                                                <li className="nav-item mx-2" key={key} >
                                                                    <a href={link} className={"nav-link " + (page_location !== '' && page_location === current_top_check ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                        {v.title}
                                                                    </a>
                                                                </li>
                                                            
                                                        )

                                                    }
                                                /* } */
                                                /* return (
                                                    <li className="nav-item" key={key}>
                                                        <Link activeClassName="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                            className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                            {v.title}
                                                        </Link>
                                                    </li>
                                                ) */
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    }
                </section >
            </div >
            }
        </>



    )
};
export default Navbar;