import React, { useState, useEffect } from 'react';

//import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, translate, /*set_language*/ } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
//import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function PartnerCards(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [galleries_title, set_galleries_title] = useState('')
    const [galleries_subtitle, set_galleries_subtitle] = useState('')


    const [isloading, setLoading] = useState(true);

    //const [lang, set_lang] = useState('pt');
    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    const [list_content, set_list_content] = useState([]);

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            for (let el of props.info.state.dados_config.content[0].components) {

                if(el.info.tag === 'gallery' && el.info.active) {
                    set_galleries_title(translate(el.info.title, lang))
                    set_galleries_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/gallery`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info, lang]);

    return (isloading ?
		<Loader />
    :
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="gallery-cards my-5">
                <div id="gallery-cards" className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {galleries_title}
                            </h3>
                            {
                                galleries_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {galleries_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                    </div>
                    {
                        list_content.length > 0 ?
                            <div className="row justify-content-center">
                                {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {
                                                return (
                                                    <div key={k} className="col-6 col-md-4 col-lg-3 mb-4">
                                                        <a href={'./' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : 'gallery/' + content.id)} title={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}>
                                                            <div className="card gallery-item">
                                                                    <div className="gallery-img"> 
                                                                        {
                                                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                                
                                                                                el['language'][lang_id]['media']['image'][0]['type']  === 'video'? 
                                                                                
                                                                                    <img 
                                                                                        src={'https://i1.ytimg.com/vi/' + el['language'][lang_id]['media']['image'][0]['link'].replace('watch?v=', 'embed/').split('/').pop() +'/0.jpg'} 
                                                                                        className="img-fluid" 
                                                                                        alt={el['language'][lang_id]['media']['image'][0]['name']} 
                                                                                        title={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)} 
                                                                                    />
                                                                                :
                                                                                    <AssecImage 
                                                                                        linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                                    />
                                                                            :
                                                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                                                    el['media']['image'][0]['type']  === 'video'? 
                                                                                
                                                                                        <img
                                                                                            src={'https://i1.ytimg.com/vi/' + el['media']['image'][0]['link'].replace('watch?v=', 'embed/').split('/').pop() +'/0.jpg'}
                                                                                            className="img-fluid"
                                                                                            alt={el['media']['image'][0]['name']}
                                                                                            title={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                                        />
                                                                                    :
                                                                                        <AssecImage 
                                                                                            linkImage={el['media']['image'][0]['link']}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el['media']['image'][0]['name']}
                                                                                        />
                                                                                ) : null 
                                                                        }
                                                                    </div>
                                                                    {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                                    <div className="card-body text-center">
                                                                        <div className="card-title mb-0">
                                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                        </div>
                                                                        {/*
                                                                            el.abstract !== '' ?
                                                                                <div className="card-text">{ReactHtmlParser(el.abstract)}</div>  
                                                                            : null 
                                                                        */}                                                              
                                                                    </div>
                                                                {/*<div className="card-footer text-center">
                                                                    <a className="btn btn-text" href={'./gallery/' + content.id} title={language[lang].see_gallery}>
                                                                        {language[lang].see_gallery}
                                                                        <i className="bi bi-chevron-right"></i>
                                                                    </a>
                                                                </div>*/}
                                                            </div>
                                                        </a>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        : null
                    }
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}