
import Swal from "sweetalert2"
import { SIMCore_API_WS, nome_empresa } from "./HOSTS"
import { network } from "./UseService"
import { translate_generic_store } from "./utils"
import { language } from "./Language"
import { Redirect } from "react-router-dom/cjs/react-router-dom"

const func_ws = () => {
  let ws = null // nao pode ser iniciado aqui pois quando faço logout nao funciona, pois a ligacao ja existe

  const return_ws = () => {
    return ws
  }
  const get_info_ws = (update_timer, set_lista_events, set_lista_carrinho, id_user, linguagem, ws, get_lista_carrinho) => {
    try {

      ws.send(JSON.stringify({ id_client: id_user }))

      ws.onmessage = async (event) => {

        let data_parse = JSON.parse(event.data)
        let type = data_parse.type
        console.log('data_parse1', data_parse)

        // type existe pois é uma forma de identificar se o server esta a enviar 
        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
        switch (type) {
          case 'update_list':  // atualiza carrinho e lista de produtos
            try {
              console.log('update_list1', data_parse)
              let id_user_from_socket = data_parse.info.id
              let carrinho_user_from_socket = data_parse.info.carrinho
              //  let lista_prod_user_from_socket = data_parse.lista_produtos

              if (parseInt(id_user_from_socket) === parseInt(id_user)) { // se for o mesmo id de cliente significa que e necessario atualizar o carrinho
                if (get_lista_carrinho().length > 0 && carrinho_user_from_socket.length === 0) {

                  window.location.reload()

                }
                let temp =[]//await repor_prods_from_carrinho(carrinho_user_from_socket)

                set_lista_carrinho(carrinho_user_from_socket, true, temp)

              }

              //set_lista_events(lista_prod_user_from_socket)// tem de ser sempre atualizado pois quando esta informacao vem do servidor significa que alguem adicionou produtos no carrinho

            } catch (erro) {
              console.log('erro open_connection_wss', erro)
            }


            break;
          case 'temporal_segundo': // caso do server enviar informacao para atualizar o countdown de um produto
            //console.error('temporal_segundo1', data_parse.data.tempo)
            // update_timer(data_parse.data)
            try {

              if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {

                if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                  let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)

                  //alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                  Swal.fire({
                    title: '',
                    text: language[linguagem].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[linguagem].label_final_frase_de_remocao_de_prod,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#0095e8',
                    confirmButtonText: 'Confirmar',
                  })
                    .then((result) => {
                      window.location.reload()

                    })
                } else {

                  if ((data_parse.data.tempo).toString() === '59:59') {

                    update_timer('')

                  } else {

                    update_timer(data_parse.data)

                  }

                }
              }

            } catch (erro) {
              console.log('erro cativar_prod1222 temporal_segundo', erro)
            }


            break;
          case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd

            if (parseInt(data_parse.id_user) === parseInt(id_user)) {
              Swal.fire({
                title: '',
                text: translate_generic_store(data_parse.data, linguagem.toLowerCase()),
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#0095e8',
                confirmButtonText: 'Confirmar',
              })
                .then((result) => {
                  window.location.reload()
                })
              // alert(translate_generic_store(data_parse.data, linguagem.toLowerCase()))

            }

            break;
          case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd

            if (parseInt(data_parse.id_user) === parseInt(id_user)) {
              Swal.fire({
                title: '',
                text: translate_generic_store(data_parse.data, linguagem.toLowerCase()),
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#0095e8',
                confirmButtonText: 'Confirmar',
              })
                .then((result) => {
                  console.log('sucesso1')
                  window.location.reload()
                  // window.location.reload()

                })
              // alert(translate_generic_store(data_parse.data, linguagem.toLowerCase()))

            }

            break;
          default:
            break;
        }
      };
    } catch (error) {
      console.log('get_info_ws error', error)
    }
  }

  const func_catch_timer = (event, set_lista_carrinho, lista_carrinho, id_user) => {
    try {
      // esta funcao é chamada para atualizar o tempo no carrinh0
      let data_parse = JSON.parse(event.data)

      if (parseInt(id_user) === parseInt(data_parse.data.id_user)) { // verificar se estou a receber info sobre o cliente correto

        let aux_list = lista_carrinho().map((el) => {

          if (el.timestamp === data_parse.data.timestamp_prod) { // verificar se estou a atulizar o tempo no produto correto
            el.tempo = data_parse.data.tempo
            return el
          } else {
            return el
          }

        })

        set_lista_carrinho(aux_list, false)
      }
    } catch (error) {
      console.log('func_catch_timer error', error)
    }

  }
  const close_ws = (ws) => {

    try {
      ws.close() // fechar o ws no caso de logout
      ws = null
    } catch (error) {
      console.log('close_ws error', error)
    }

  }

  const verify_if_wss_is_open = (ws) => {

    try {

      return ws === null ? false : ws.readyState === WebSocket.OPEN
    } catch (error) {
      console.log('verify_if_wss_is_open error', error)
      return false
    }

  }

  const repor_prods_from_carrinho = async (list) => {
    try {
      let new_list_carrinho_prod = []
      let obj_to_provider = {}

      await Promise.all(list.map(async (el) => {
        // se o prod tiver timeout e o tempo for 0 entao é invalido
        // procurar o prod na base de dados e adicionar
        try {

          let aux_prod = await network().get_produtos_by_id(el.id_prod)
          let obj_stock = await network().get_stocks_by_id_da_linha_de_stock(el.id_stock)
          let array_precos = await network().get_preco_by_id_da_lista_de_stock(el.id_stock)


          obj_to_provider = aux_prod

          let array_hora_fim = (obj_stock[0].end_at).split(':')
          let array_hora_inicio = (obj_stock[0].start_at).split(':')

          obj_to_provider.details[nome_empresa].dia = (obj_stock[0].event_date).split('T')[0]
          obj_to_provider.details[nome_empresa].validade_data_hora_fim = array_hora_fim[0] + ':' + array_hora_fim[1]
          obj_to_provider.details[nome_empresa].validade_data_hora_ini = array_hora_inicio[0] + ':' + array_hora_inicio[1]
          obj_to_provider.details[nome_empresa].id_event = obj_stock[0].id_event === null ? 0 : obj_stock[0].id_event
          obj_to_provider.details[nome_empresa].id_stock = obj_stock[0].id
          obj_to_provider.total_liquido = array_precos === undefined ? '0' : array_precos.preco_liquido

          let new_composicao = obj_to_provider.composicao.map((el) => {

            if (el.principal === true) {

              el.valores = array_precos === undefined ? [] : array_precos

            }
            return el

          })

          obj_to_provider.composicao = new_composicao

          new_list_carrinho_prod.push({
            produto: obj_to_provider, // se eu meter quantidade a zero, ele depois vai entarr o produto com a quantidade
            quantidade: el.quantidade,

          })

        } catch (error) {
          console.log('network().get_produtos_by_id error', error)
        }

      }))

      return new_list_carrinho_prod

    } catch (error) {
      console.log('repor_prods_from_carrinho error', error)
      return []
    }
  }

  const open_connection_wss = async (update_timer, set_lista_events, set_lista_carrinho, id_user, lista_carrinho, refreshlista, linguagem, set_ws_info, get_id_user, get_lista_carrinho) => {

    try {
      //  console.log('flag_esta_conectado', flag_esta_conectado)
      //let aux =111111
      /*   alert('aquiioii id_user'+id_user)
        //alert('aquiioii aux',aux)
        alert('aquiioii get_id_user'+ get_id_user())
       */

      ws = null;
      ws = new WebSocket(SIMCore_API_WS);
      ws.onclose = async (r) => {
        /*  console.error('rrrrrrrr',JSON.stringify(r, null, '\t'))
         console.error('rrrrrrrr',r) */
        // servidor falhou? {"code": 1001, "isTrusted": false, "reason": "Stream end encountered"}
        //Indicates an endpoint is being removed. Either the server or client will become unavailable "code": 1001,
        // sai porque quis? {"code": 1000, "isTrusted": false, "reason": null}
        //  indicates a normal closure "code": 1000
        console.log('r', r)

        if (parseInt(r.code) !== 1000 && parseInt(r.code) !== 1001 && parseInt(r.code) !== 1006) {
          // reset lista
          refreshlista()

          // alert 
          Swal.fire({
            title: '',
            text: language[linguagem.toLowerCase()].label_aviso_servidor_foi_a_baixo,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#0095e8',
            confirmButtonText: 'Confirmar',
          })
            .then((result) => {
              //usarToken().logout()
              //window.location = WEB_SITE_URI + 'store'
              window.location.reload(false)

              //  return <Redirect to={'/store'}/>
              //  window.history.go(-2);

            })
          /* alert(linguagem === 'EN' ? 'The internet connection has been interrupted, your shopping cart has been deleted, to reconnect please restart the application.'
            : 'A ligação à internet foi interrompida, o seu carrinho de compras foi apagado, para voltar a ligar-se por favor reinicie a aplicação.'
          ) */
        } else if (parseInt(r.code) === 1006) {
          //    window.location.reload(false)
          //setTimeout(function () {
          //connect();
          refreshlista()
          window.location.reload(false)
          /* 
                    let flag_is_open = verify_if_wss_is_open(ws)
                    console.error(' flag_is_open', flag_is_open)
                    console.error(' ws', ws)
                    if (!flag_is_open) {
                      //ws.close()
                     // await on_init(() => { }, history_action)
                     refreshlista()
          
                     window.addEventListener("focus", async () => await on_init(() => { }, history_action));
          
                      console.error(' ws', ws)
          
                    }
                    console.error(' verify_if_wss_is_open(ws)', verify_if_wss_is_open(ws))
           */

          /* else {




            Swal.fire({
              title: '',
              text: language[linguagem.toLowerCase()].label_aviso_servidor_foi_a_baixo,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#0095e8',
              confirmButtonText: 'Confirmar',
            })
              .then((result) => {
                console.error('parseInt(r.code) === 1006')
                refreshlista()

                window.addEventListener("focus", async () => await on_init(() => { }));


              })


          } */
        }


      };
      ws.onopen = async () => {

        set_ws_info(ws)
        // console.log('id_user', id_user)
        // primero tenho de mandar o id para o servidor verificar se nao tenho carrinho ja criado
        ws.send(JSON.stringify({ id_client: id_user }))

        ws.onmessage = async (event) => {

          let data_parse = JSON.parse(event.data)
          let type = data_parse.type
          // console.log('data_parse2', data_parse)

          // type existe pois é uma forma de identificar se o server esta a enviar 
          // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
          // console.error('data_parse', data_parse)
          switch (type) {
            case 'update_list':  // atualiza carrinho e lista de produtos
              try {
                /* console.error('update_list2', data_parse)
                console.error('data_parse.info.id', data_parse.info.id)
                console.error('id_user', id_user)
                console.error('lista_carrinho', lista_carrinho)
                console.error('data_parse.info.carrinho', data_parse.info.carrinho) */

                let id_user_from_socket = data_parse.info.id
                let carrinho_user_from_socket = data_parse.info.carrinho
                //           let lista_prod_user_from_socket = data_parse.lista_produtos

                if (parseInt(id_user_from_socket) === parseInt(id_user)) { // se for o mesmo id de cliente significa que e necessario atualizar o carrinho
                  if (get_lista_carrinho().length > 0 && carrinho_user_from_socket.length === 0) {

                    window.location.reload()

                  }
                  let temp = []//await repor_prods_from_carrinho(carrinho_user_from_socket)
                  set_lista_carrinho(carrinho_user_from_socket, true, temp)


                }

                // console.log('lista_prod_user_from_socket', lista_prod_user_from_socket)
                // set_lista_events(lista_prod_user_from_socket)// tem de ser sempre atualizado pois quando esta informacao vem do servidor significa que alguem adicionou produtos no carrinho
              } catch (erro) {
                console.log('erro open_connection_wss', erro)
              }

              break;
            case 'temporal_segundo': // caso do server enviar informacao para atualizar o countdown de um produto
              //update_timer(data_parse.data)

              //func_catch_timer(event, set_lista_carrinho, lista_carrinho, id_user)
              // console.error('temporal_segundo1111', data_parse)
              try {
                if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {
                  if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                    let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)

                    // alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
                    Swal.fire({
                      title: '',
                      text: language[linguagem.toLowerCase()].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[linguagem.toLowerCase()].label_final_frase_de_remocao_de_prod,
                      icon: 'warning',
                      showCancelButton: false,
                      confirmButtonColor: '#0095e8',
                      confirmButtonText: 'Confirmar',
                    })
                      .then((result) => {

                        window.location.reload()

                      })
                  } else {
                    update_timer(data_parse.data)

                  }
                }
              } catch (erro) {
                console.log('erro cativar_prod1222d', erro)

              }
              break;
            case 'erro': // mensagem de erro caso o obj nao ter sido bem atualizado na bd
              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, linguagem.toLowerCase()),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: 'Confirmar',
                })
                  .then((result) => {
                    window.location.reload()
                  })
                //alert(translate_generic_store(data_parse.data, linguagem.toLowerCase()))

              }

              break;
            case 'sucesso': // mensagem de sucesso caso o obj ter sido bem atualizado na bd
              console.log('sucesso2')

              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                //func_costum().translate(prod_principal.descricao.nome, linguagem.toLowerCase())
                //alert(translate_generic_store(data_parse.data, linguagem.toLowerCase()))
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, linguagem.toLowerCase()),
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: 'Confirmar',
                })
                  .then((result) => {
                    window.location.reload()

                    // window.location.reload()

                  })

              }
              break;
            default:
              break;
          }
        };
      };
      /*   ws.addEventListener = ('upgrade', e => {
          console.log('recebi mensagem', e)
        })
        ws.addEventListener = ('message', e => {
          console.log('recebi mensagemww', e)
        }) */
      ws.onerror = (e) => {
        console.log('error server', e);
        /* Swal.fire({
          title: '111',
          text: language[linguagem.toLowerCase()].label_aviso_servidor_foi_a_baixo,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#0095e8',
          confirmButtonText: 'Confirmar',
        })
          .then((result) => {
            //usarToken().logout()
            //window.location = WEB_SITE_URI + 'store'
            //window.location.reload(false)
  
            //  return <Redirect to={'/store'}/>
            //  window.history.go(-2);
  
          }) */

      };
      ws.onmessage = (event) => {
        func_catch_timer(event, set_lista_carrinho, lista_carrinho, id_user)
      };

    } catch (error) {
      console.log('open_connection_wss eroror', error)
    }
  }
  const conecta_e_cativa_prod_stock = (refreshlista, horario_sessao, flag_update_prod, update_timer, addprod, updateprodpositive, lang, language, set_lista_carrinho, get_id_user, obj, get_lista_carrinho) => {
    ws = new WebSocket(SIMCore_API_WS);
    ws.onopen = async () => {
      let id_user = get_id_user()
      let prod = {
        id_prod: obj.id_prod,
        id_event: '',
        quantidade: 1,// o produto é sempre add um de cada vez
        id_user: id_user,
        prod_timer: 0, //  zero pois este prod nao necessita de countdown
        lista_incompativel: [], // os stocks nunca tem eventos sobrepostos
        id_stock: obj.id_stock,
        info: {
          produto: obj,
          quantidade: 1,
        }
      }

      // nesta fase o prod ja tem o id client
      ws.send(JSON.stringify({ cativar: true, data: [prod] }))

      ws.onmessage = async (event) => {

        let data_parse = JSON.parse(event.data)
        let type = data_parse.type

        // type existe pois é uma forma de identificar se o server esta a enviar 
        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
        switch (type) {
          case 'update_list': // atualiza carrinho e lista de produtos

            try {

              if (parseInt(id_user) === parseInt(data_parse.info.id)) {
                if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                  window.location.reload()

                }
                let temp = []//await repor_prods_from_carrinho(data_parse.info.carrinho)

                set_lista_carrinho(data_parse.info.carrinho, false, temp)
              }

              //  set_lista_events(data_parse.lista_produtos)

            } catch (erro) {
              console.log('erro cativar_prod1', erro)
            }

            break;
          case 'temporal_segundo':  // caso do server enviar informacao para atualizar o countdown de um produto
            try {
              // console.error('temporal_segundo3', data_parse.data.tempo)


              if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {

                if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                  let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                  Swal.fire({
                    title: '',
                    text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,

                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#0095e8',
                    confirmButtonText: language[lang].label_confirmar,
                  })
                    .then((result) => {
                      window.location.reload()

                    })

                  //  alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra

                } else {

                  if ((data_parse.data.tempo).toString() === '59:59') {

                    update_timer('')

                  } else {

                    update_timer(data_parse.data)

                  }

                }
              }

            } catch (erro) {
              console.log('erro cativar_prod1222e', erro)
            }

            break;
          case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd
            try {

              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: language[lang].label_confirmar,
                })
                  .then((result) => {
                    window.location.reload()
                  })
                //alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))

              }
            } catch (erro) {
              console.log('switch erro send_stock_to_ws error:', erro)

            }


            break;
          case 'sucesso':  // mensagem de sucesso caso o obj ter sido bem atualizado na bd
            console.log('sucesso3')

            try {

              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                if (flag_update_prod) {

                  updateprodpositive(horario_sessao.id_stock) // function vinda do provider, atualiza a quantidade para 2 ou mais 
                } else {

                  addprod(obj) // funcao do provider, adiciona o produto na lista 
                }
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: language[lang].label_confirmar,
                })
                  .then((result) => {
                    window.location.reload()


                  })
                // alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))

              }
            } catch (erro) {
              console.log('switch sucesso send_stock_to_ws error:', erro)

            }

            break;
          default:
            break;
        }
      };
    }
    ws.onclose = (r) => {
      // servidor falhou? {"code": 1001, "isTrusted": false, "reason": "Stream end encountered"}
      //Indicates an endpoint is being removed. Either the server or client will become unavailable "code": 1001,
      // sai porque quis? {"code": 1000, "isTrusted": false, "reason": null}
      //  indicates a normal closure "code": 1000

      if (parseInt(r.code) !== 1000 && parseInt(r.code) !== 1001) {
        // reset lista
        refreshlista()

        // alert 
        /*  Swal.fire({
           title: '',
           text: language[lang.toLowerCase()].label_aviso_servidor_foi_a_baixo,
           icon: 'error',
           showCancelButton: false,
           confirmButtonColor: '#0095e8',
           confirmButtonText: 'Confirmar',
         })
           .then((result) => {
             console.log('Swal22221') */
        //usarToken().logout()
        //window.location = WEB_SITE_URI + 'store'
        window.location.reload(false)
        //  return <Redirect to={'/store'}/>
        //  window.history.go(-2);

        // })
        /*  alert( '11The internet connection has been interrupted, your shopping cart has been deleted, to reconnect please restart the application.'
         )  */
      }


    };
  }

  const conecta_e_cativa_prod_event = (refreshlista, addprod, update_timer, lang, set_lista_carrinho, aux_list_to_cative, duracao_min, id_user, obj, get_lista_carrinho) => {
    ws = new WebSocket(SIMCore_API_WS);
    ws.onopen = async () => {
      let prod = {
        id_prod: obj.id_prod,
        id_event: obj.id_event,
        quantidade: 1,
        id_user: id_user,
        prod_timer: duracao_min,
        lista_incompativel: aux_list_to_cative.data,
        id_stock: obj.id_stock,
        info: {
          produto: obj,
          quantidade: 1,
        }
      }

      // cativar produto é ativada quando o client adiciona produto no carrinho
      // nesta fase o prod ja tem o id client
      ws.send(JSON.stringify({ cativar: true, data: [prod] }))

      ws.onmessage = async (event) => {

        let data_parse = JSON.parse(event.data)
        let type = data_parse.type
        console.log('data_parse4', data_parse)

        // type existe pois é uma forma de identificar o que o server esta a enviar 
        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
        switch (type) {
          case 'update_list': // atualiza carrinho e lista de produtos

            try {

              if (parseInt(id_user) === parseInt(data_parse.info.id)) {
                if (get_lista_carrinho().length > 0 && data_parse.info.carrinho.length === 0) {

                  window.location.reload()

                }
                let temp = []//await repor_prods_from_carrinho(data_parse.info.carrinho)

                set_lista_carrinho(data_parse.info.carrinho, false, temp)// funcao vinda do provider
              }

              // set_lista_events(data_parse.lista_produtos)// funcao vinda do provider

            } catch (erro) {
              console.log('erro cativar_prod1', erro)
            }

            break;

          case 'temporal_segundo':  // caso do server enviar informacao para atualizar o countdown de um produto
            //console.error('temporal_segundo4', data_parse.data.tempo)

            try {

              if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {

                if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                  let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                  Swal.fire({
                    title: '',
                    text: language[lang].label_introducao_frase_de_remocao_de_prod + aux_prod_que_foi_eliminado.nome + language[lang].label_final_frase_de_remocao_de_prod,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#0095e8',
                    confirmButtonText: language[lang].label_confirmar,
                  })
                    .then((result) => {
                      window.location.reload()

                    })
                  //  alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra

                } else {

                  if ((data_parse.data.tempo).toString() === '59:59') {

                    update_timer('')

                  } else {

                    update_timer(data_parse.data)

                  }

                }
              }

            } catch (erro) {
              console.log('erro cativar_prod12223', erro)
            }

            break;
          case 'erro':  // mensagem de erro caso o obj nao ter sido bem atualizado na bd

            try {


              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: language[lang].label_confirmar,
                })
                  .then((result) => {
                    window.location.reload()
                  })
                // alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))

              }
            } catch (erro) {
              console.log('switch erro send_sessao_to_ws error:', erro)
            }


            break;
          case 'sucesso': // mensagem de sucesso caso o obj ter sido bem atualizado na bd
            console.log('sucesso4')

            try {

              if (parseInt(data_parse.id_user) === parseInt(id_user)) {
                addprod(obj)// funcao vinda do provider 
                Swal.fire({
                  title: '',
                  text: translate_generic_store(data_parse.data, lang.toLowerCase()),
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#0095e8',
                  confirmButtonText: language[lang].label_confirmar,
                })
                  .then((result) => {
                    window.location.reload()

                  })
                // alert(translate_generic_store(data_parse.data, get_linguagem().toLowerCase()))

              }
            } catch (erro) {
              console.log('switch sucesso send_sessao_to_ws error:', erro)
            }


            break;
          default:
            break;
        }
      };
    }
    ws.onclose = (r) => {
      // servidor falhou? {"code": 1001, "isTrusted": false, "reason": "Stream end encountered"}
      //Indicates an endpoint is being removed. Either the server or client will become unavailable "code": 1001,
      // sai porque quis? {"code": 1000, "isTrusted": false, "reason": null}
      //  indicates a normal closure "code": 1000
      if (parseInt(r.code) !== 1000 && parseInt(r.code) !== 1001) {
        // reset lista
        refreshlista()

        // alert 
        /*  Swal.fire({
           title: '',
           text: language[lang.toLowerCase()].label_aviso_servidor_foi_a_baixo,
           icon: 'error',
           showCancelButton: false,
           confirmButtonColor: '#0095e8',
           confirmButtonText: 'Confirmar',
         })
           .then((result) => {
             console.log('Swal22221') */
        //usarToken().logout()
        //window.location = WEB_SITE_URI + 'store'
        window.location.reload(false)

        //  return <Redirect to={'/store'}/>
        //  window.history.go(-2);

        // })
        /* alert(linguagem === 'EN' ? 'The internet connection has been interrupted, your shopping cart has been deleted, to reconnect please restart the application.'
          : 'A ligação à internet foi interrompida, o seu carrinho de compras foi apagado, para voltar a ligar-se por favor reinicie a aplicação.'
        ) */
      }


    };
  }

  /* const cativar_prod = (prod, set_lista_events, set_lista_carrinho, id_user, lista_carrinho, linguagem) => {
  
    try {
      // cativar produto é ativada quando o client adiciona produto no carrinho
  
      // nesta fase o prod ja tem o id client
      ws.send(JSON.stringify({ cativar: true, data: [prod] }))
  
      ws.onmessage = async (event) => {
  
        let data_parse = JSON.parse(event.data)
        let type = data_parse.type
  
        // type existe pois é uma forma de identificar se o server esta a enviar 
        // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
        switch (type) {
          case 'update_list': {// atualiza carrinho e lista de produtos
  
            try {
  
              if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                set_lista_carrinho(data_parse.info.carrinho, false)
                set_lista_events(data_parse.lista_produtos)
  
              } else {
                set_lista_events(data_parse.lista_produtos)
  
              }
  
            } catch (erro) {
              console.log('erro cativar_prod3', erro)
            }
  
          }
            break;
          case 'temporal_segundo': { // caso do server enviar informacao para atualizar o countdown de um produto
            if (parseInt(id_user) === parseInt(data_parse.data.id_user)) {
  
              if ((data_parse.data.tempo).toString() === '00:00') { // verificar se o countdown do produto chegou a zero
                let aux_prod_que_foi_eliminado = await network().get_produtos_by_id(data_parse.data.id_prod)
                console.log('aux_prod_que_foi_eliminado6', aux_prod_que_foi_eliminado)
                alert('O produto ' + aux_prod_que_foi_eliminado.nome + ' foi removido do seu carrinho') // enviar alert para qualquer ecra
  
              } else {
  
                // atualizar countdown
                let new_list = lista_carrinho.map((el) => {
                  if (el.timestamp === data_parse.data.timestamp_prod) {
                    el.tempo = data_parse.data.tempo
                    return el
                  } else {
                    return el
                  }
                })
  
                set_lista_carrinho(new_list, false)
              }
            }
          }
            break;
  
          case 'erro': { // mensagem de erro caso o obj nao ter sido bem atualizado na bd
  
            if (parseInt(data_parse.id_user) === parseInt(id_user)) {
              alert(translate(data_parse.data, linguagem.toLowerCase()))
            }
  
          }
            break;
          case 'sucesso': { // mensagem de sucesso caso o obj ter sido bem atualizado na bd
  
            if (parseInt(data_parse.id_user) === parseInt(id_user)) {
              alert(translate(data_parse.data, linguagem.toLowerCase()))
            }
  
          }
            break;
          default:
            break;
        }
      };
    } catch (error) {
      console.log('cativar_prod error', error)
    }
  }
  */
  /*  const nao_cativar_prod = (id_user, prod_lista, set_lista_events, set_lista_carrinho, lista_carrinho, linguagem) => {
   
     try {
   
       // distinguir se estou a apagar um produto ou a lista toda dos produtos
       if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
         ws.send(JSON.stringify({ cativar: false, data: prod_lista }))
       } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
         ws.send(JSON.stringify({ id_user: id_user, cativar: false, data: prod_lista }))
       }
       ws.onmessage = (event) => {
   
         let data_parse = JSON.parse(event.data)
         let type = data_parse.type
         // type existe pois é uma forma de identificar se o server esta a enviar 
         // informacao para atualizar carrinho e lista de stockes ou se esta a atualizar o countdown de um produto 
         switch (type) {
           case 'update_list': {
             try {
               if (parseInt(id_user) === parseInt(data_parse.info.id_client)) {
                 set_lista_carrinho(data_parse.info.carrinho, false)
               }
               set_lista_events(data_parse.lista_produtos)
             } catch (erro) {
               console.log('erro cativar_prod2', erro)
             }
           }
             break;
           case 'temporal_segundo': {
             func_catch_timer(event, set_lista_carrinho, lista_carrinho, id_user)
           }
             break;
           case 'erro': { // mensagem de erro caso o obj nao ter sido bem atualizado na bd
             if (parseInt(data_parse.id_user) === parseInt(id_user)) {
   
               alert(translate(data_parse.data, linguagem.toLowerCase()))
             }
           }
             break;
           case 'sucesso': { // mensagem de sucesso caso o obj ter sido bem atualizado na bd
             if (parseInt(data_parse.id_user) === parseInt(id_user)) {
   
               alert(translate(data_parse.data, linguagem.toLowerCase()))
             }
           }
             break;
           default:
             break;
         }
       };
     } catch (error) {
       console.log('nao_cativar_prod error', error)
     }
   } */
  const comprar_prod = (id_user, prod_lista, ws) => {
    console.log('passou aqui', prod_lista)
    console.log('passou aqui2', id_user)
    console.log('passou aqui2', ws)
    // ws = new WebSocket(SIMCore_API_WS);

    try {
      // distinguir se estou a apagar um produto ou a lista toda dos produtos
      if (prod_lista.length === 1) { // caso o utilizador pressione o botao de apagar todos os produtos do carrinho, mas essa lista apenar ter um produto
        console.log('mandouuuuuuu11111')
        ws.send(JSON.stringify({ id_user: id_user, flag_comprar_prod: true, cativar: false, data: prod_lista }))
      } else { // caso te ter clicado em apagar tudo da lista de carrinho sendo que esta tem mais do que um produto
        console.log('mandouuuuuuu1111122222222')

        ws.send(JSON.stringify({ flag_comprar_prod: true, id_user: id_user, cativar: false, data: prod_lista }))
      }
    } catch (error) {
      console.log('nao_cativar_prod error', error)
    }


  }
  const teste = () => {
    return <Redirect to={'/store'} />
    //window.history.go(-2);//history.pushState({}, '','/store' )
    // window.navigator.// ('/store')
  }

  return {
    open_connection_wss,
    teste,
    /* cativar_prod,
    nao_cativar_prod, */
    close_ws,
    verify_if_wss_is_open,
    return_ws,
    comprar_prod,
    repor_prods_from_carrinho,
    get_info_ws,
    func_catch_timer,
    conecta_e_cativa_prod_stock,
    conecta_e_cativa_prod_event
  }
}
export default func_ws
//export default () => { }