import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//import { Document, Page } from 'react-pdf';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, translate } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"
// import { translate_generic_store } from '../GenericStore/utils';

export default function RoutesCards(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    // const data_interface = {
    //     id: '',
    //     info: {},
    //     content: {}
    // }
    // const [content, set_content] = useState(data_interface);
    const [content_parent, set_content_parent] = useState(null);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            }

        try {

            let getInfoList = async () => {

                await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                    .then(res => {
                        if(res.data.length > 0) {
                            res.data.forEach((v) => {
                                if (v.info.principal === true) {
                                    //set_dados(dados.current = v);

                                    v.content.childs.forEach((el) => {
                                        
                                        if(el.id_content === '' && ('/' + el.link === pathname)) {
                                            if(el['parent'] !== undefined) {
                                                console.log(el['parent'])
                                            }
                                        }

                                        if(el['children'] !== undefined && el['children'].length > 0) {
                                            el['children'].forEach(async (el_c) => {
                                                if(el_c.id_content === '' && ('/' + el_c.link === pathname)) {
                                                    if(el_c['parent'] !== undefined) {

                                                        await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c['parent']}`)
                                                            .then(res => { 
                                                                if(res.data.length > 0) {
                                            
                                                                    var hoje = new Date();
                                                                    let status = 0
                                                                    if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                        var date_p = new Date(res.data[0].info.date.published);
                                            
                                                                        status = 1
                                                                        if(hoje >= date_p) 
                                                                            status = 2
                                                                    }
                                            
                                                                    if(status !== 2) {
                                                                        res.data[0].content = [{text: 'rascunho ou agendado'}]
                                                                        set_content_parent(res.data[0])   
                                                                    }
                                                                    else {
                                                                        set_content_parent(res.data[0]) 
                                                                    }
                                                                }
                                                            })
                                                            .catch(erro => console.log('ERROR', erro))

                                                    }
                                                }
                                            })
                                        }

                                    })
                                    
                                }
                            })
                        }
                        else {
                            return []
                        }

                    })
                    .catch(erro => console.log('ERROR', erro))

                await reqGET(`${Hosts.SIMCore_API}/platform/routes/grupo/ROT`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            let aux = []
                            res.data.forEach((v) => {
                                console.log('v.activo', v.activo)
                                console.log('v.visible', v.visible)
                              if(v.activo && v.visible) {
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            console.log('ROUTES', aux)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props, pathname]);

    return (isloading ?
		<Loader />
	:
        <>
            <Navbar />
            <main className="routes-cards">
                <section id="routes-cards" className="content-body">

                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-12 bg-light py-5'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3 className="titulo-primario">
                                                <span>{language[lang].routes}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 py-3">

                                <div className="row">
                                    {
                                    content_parent !== undefined && content_parent !== null ?
                                        <div className="div-content col-12 mb-5">
                                            <h2 className={content_parent['content'] !== undefined && content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? "titulo-primario" : "d-none"}>
                                                {parse(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].title : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].title === undefined || content_parent['content'][0].language[lang_id].title === '' ? content_parent['content'][0].title : content_parent['content'][0].language[lang_id].title))}
                                            </h2>
                                            <h2 className={content_parent['content'] !== undefined && content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                                {parse(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].subtitle : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].subtitle === undefined || content_parent['content'][0].language[lang_id].subtitle === '' ? content_parent['content'][0].subtitle : content_parent['content'][0].language[lang_id].subtitle))}
                                            </h2>
                                            <div className={"div-text"}>
                                                {ReactHtmlParser(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].text : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].text === undefined || content_parent['content'][0].language[lang_id].text === '' ? content_parent['content'][0].text : content_parent['content'][0].language[lang_id].text))}
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>

                                <div className="row p-3">
                                    {
                                    list_content !== undefined ?
                                        list_content.map((content) => {
                                            return (
                                                content.composicao.map((el, k) => {  
                                                    return (
                                                        <div key={k} className="col-6 col-md-4 col-lg-4 my-3">
                                                            <div className="card routes-item hover-animate border-0">
                                                                <a href={'./routes/' + (content.id)} title={el.title}>
                                                                    {
                                                                    el['multimedia'] !== undefined && el['multimedia']['link_images'] !== undefined && el['multimedia']['link_images'].length > 0 ?
                                                                        <div className="routes-img"> 
                                                                            <AssecImage 
                                                                                linkImage={el['multimedia']['link_images'][0][0]['link']}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid rounded mb-2" 
                                                                                alt={el['multimedia']['link_images'][0][0]['name']}
                                                                            />
                                                                            {/*<img src={el['media']['image'][0][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                                        </div>
                                                                    :
                                                                        null
                                                                    }
                                                                    
                                                                    <div className="card-body pb-3 d-none d-md-block bg-light rounded">
                                                                        <div className="card-bar mb-3"></div>
                                                                        <div className="card-title">
                                                                            {translate(el.descricao.nome, lang)}
                                                                        </div>
                                                                        <div className="card-text limit-5-lines">
                                                                            {ReactHtmlParser(translate(el.descricao.descricao, lang))}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className="card-footer text-center d-none">
                                                                <a className="btn btn-text" href={'./routes/' + content.id} title={language[lang].see_restaurant}>{language[lang].see_restaurant}<i className="bi bi-chevron-right"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        })
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}