import React, { useState, useEffect, useRef } from 'react';
import './Slider.css';
import '../Pagina.css';
import '../Animate/animate.css';

import { /*reqGET,*/ set_language, content_language, repo_img_link/*, simpleAxiosGet */, translate} from '../../../Utils';

import * as Hosts from "../../../Hosts";

import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

import { language } from '../WEBContent/Language';

import Loader from '../../../loader';

const Slider = (props) => {

    const [lang, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');

    const [, set_dados] = useState(null);
	const banner = useRef(null);

    const [isloading, setLoading] = useState(true);

    const [navbarHeight, setnavbarHeight] = useState(0);
    const [sliderMarginTop, setSliderMarginTop] = useState(0);
    const [vhHeight, setvhHeight] = useState('85vh');

    useEffect(() => {

        if(props.dados_banner !== undefined && props.dados_banner !== null && props.dados_banner !== '') {
            set_dados(banner.current = props.dados_banner)

            // setvhHeight(props.dados_height.vh_height + 'vh')
            // setSliderMarginTop(props.dados_height.margin_top)
            // setnavbarHeight(props.dados_height.navbar_height)
        }

        function getNavbarHeight() {
            setTimeout(() => {
                let height = 0;
                let marginTop = 0;
                let windowWidth = window.innerWidth;
                
                if(document.getElementsByClassName('sticky-top').length > 0) {
                    height = document.getElementsByClassName('sticky-top')[0].clientHeight;
                } else {
                    if(windowWidth < 992) {
                        height = marginTop = document.getElementsByClassName('fixed-top')[0].clientHeight;
                    } else {
                        height = 0;
                    }
                }
               
                setnavbarHeight(height);
                setSliderMarginTop(marginTop);

                if(windowWidth < 992) {
                    setvhHeight('80vh');
                } else {
                    if(document.getElementsByClassName('page-01').length > 0) {
                        setvhHeight('100vh');
                    } else if(document.getElementsByClassName('page-02').length > 0) {
                        setvhHeight('70vh');
                    } else if(document.getElementsByClassName('page-03').length > 0) {
                        setvhHeight('85vh');
                    } else if(document.getElementsByClassName('page-04').length > 0) {
                        setvhHeight('100vh');
                    } else if(document.getElementsByClassName('page-05').length > 0) {
                        setvhHeight('100vh');
                    } else {
                        setvhHeight('100vh');
                    }
                }
                
            }, 350)
        }

        window.addEventListener('resize', getNavbarHeight);
        getNavbarHeight();

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setLoading(false)

        /*
        getInfo()
            .then((res) => {
                set_dados(banner.current = res)

                setTimeout(() => {
                    setLoading(false)
                }, 750)

            })
        */
    }, [props])

    return (
        isloading ?
            <div id="slider">
                <div className='swiper-slide'>
                    <Loader />
                </div>
            </div>
		:
            <>
                {
                banner.current !== undefined && banner.current !== null ?
                    <section id="slider" style={{marginTop: sliderMarginTop + "px"}}>
                        <div className="swiper swiper-container swiper-cartaz slideshow">
                            <Swiper
                                modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                                spaceBetween={0}
                                slidesPerView={1}
                                //navigation={banner.length > 1 ? true : false}
                                navigation={{
                                    nextEl: '.swiper-cartaz-button-next',
                                    prevEl: '.swiper-cartaz-button-prev',
                                }}
                                loop={banner.current.length > 1 ? true : false}
                                speed={500}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false
                                }}
                                effect="fade"
                                //pagination={banner.length > 1 ? true : false}
                                pagination={{
                                    el: '.swiper-pagination-cartaz',
                                    //dynamicBullets: true,
                                    //type: "progressbar",
                                    clickable: true
                                }}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 1,
                                        //navigation: true,
                                    },
                                    1200: {
                                        slidesPerView: 1,
                                        //navigation: true,
                                        //loop: true,
                                    },
                                }}
                            /*scrollbar={{ draggable: true }}*/
                            //onSwiper={(swiper) => console.log(swiper)}
                            //onSlideChange={() => console.log('slide change')}
                            >
                                {
                                banner.current.length > 0 ?
                                    banner.current.map((v, key) => {

                                        //console.log(v)

                                        //check if link has http (if not, add it to link)
                                        if(v.content[0].connection.t !== undefined && v.content[0].connection.t !== '' && v.content[0].connection.t === '0' && v.content[0].connection.link !== '' && v.content[0].connection.link.substr(0, 4) !== 'http') {
                                            v.content[0].connection.link = 'http://' + v.content[0].connection.link;
                                            //console.log(v.content[0].connection.link)
                                        }

                                        //if banner/image doesn't exist, add noimage_banner.jpg
                                        if(v.content[0].media.image[0] === undefined || v.content[0].media.image[0]['link'] === undefined || v.content[0].media.image[0]['link'] === '') {
                                            //console.log('nophoto', v.content[0].media.image[0])
                                            v.content[0].media.image[0] = {id: '', link: repo_img_link("nophoto_banner.jpg")}
                                        }

                                        return (
                                            (!v.info.button && (v.content[0].connection.link !== '' || v.content[0].connection.id !== '')) ?
                                                <div key={key}>
                                                    <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + v.content[0].media.image[0].link + ")", height: "calc(" + vhHeight + " - " + navbarHeight + "px)" }}>
                                                        {
                                                            v.content[0].connection.link !== '' ?

                                                                v.content[0].connection.t !== undefined && v.content[0].connection.t !== '' && v.content[0].connection.t === '0' ?

                                                                    <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" className="d-block h-100 w-100">
                                                                
                                                                        <span className={"slide-caption caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                            <h2 className="cartaz-titulo">
                                                                                {translate(v.content[0].title, lang)}
                                                                            </h2>
                                                                            {
                                                                                v.content[0].subtitle.length > 0 ?
                                                                                    <h3 className="cartaz-subtitulo">
                                                                                        {translate(v.content[0].subtitle, lang)}
                                                                                    </h3>
                                                                                : 
                                                                                    null
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                :
                                                                    <a href={Hosts.WEB_SITE_URI + v.content[0].connection.link} className="d-block h-100 w-100">
                                                                    
                                                                        <span className={"slide-caption caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                            <h2 className="cartaz-titulo">
                                                                                {translate(v.content[0].title, lang)}
                                                                            </h2>
                                                                            {
                                                                                v.content[0].subtitle.length > 0 ?
                                                                                    <h3 className="cartaz-subtitulo">
                                                                                        {translate(v.content[0].subtitle, lang)}
                                                                                    </h3>
                                                                                : 
                                                                                    null
                                                                            }
                                                                        </span>
                                                                    </a>

                                                            : 
                                                            
                                                                v.content[0].connection.slug !== undefined && v.content[0].connection.slug !== '' ?
                                                                    <a href={Hosts.WEB_SITE_URI + v.content[0].connection.slug} className="d-block h-100 w-100">
                                                                
                                                                        <span className={"slide-caption caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                            <h2 className="cartaz-titulo">
                                                                                {translate(v.content[0].title, lang)}
                                                                            </h2>
                                                                            {
                                                                                v.content[0].subtitle.length > 0 ?
                                                                                    <h3 className="cartaz-subtitulo">
                                                                                        {translate(v.content[0].subtitle, lang)}
                                                                                    </h3>
                                                                                : 
                                                                                    null
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                :
                                                                    <a href={Hosts.WEB_SITE_URI + 'pag/' + v.content[0].connection.id} /*target="_blank" rel="noreferrer"*/ className="d-block h-100 w-100">
                                                                    
                                                                        <span className={"slide-caption caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                            <h2 className="cartaz-titulo">
                                                                                {translate(v.content[0].title, lang)}
                                                                            </h2>
                                                                            {
                                                                                v.content[0].subtitle.length > 0 ?
                                                                                    <h3 className="cartaz-subtitulo">
                                                                                        {translate(v.content[0].subtitle, lang)}
                                                                                    </h3>
                                                                                : 
                                                                                    null
                                                                            }
                                                                        </span>
                                                                    </a>

                                                        }
                                                        
                                                    </SwiperSlide>
                                                </div>
                                            :
                                                <div key={key}>
                                                    <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + v.content[0].media.image[0].link + ")", height: "calc(" + vhHeight + " - " + navbarHeight + "px)" }}>
                                                                
                                                        <span className={"slide-caption caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                            <h2 className="cartaz-titulo">
                                                                {translate(v.content[0].title, lang)}
                                                            </h2>
                                                            {
                                                                v.content[0].subtitle.length > 0 ?
                                                                    <h3 className="cartaz-subtitulo">
                                                                        {translate(v.content[0].subtitle, lang)}
                                                                    </h3>
                                                                : 
                                                                    null
                                                            }
                                                            {
                                                                v.content[0].connection.link !== '' ?
                                                                    v.content[0].connection.t !== undefined && v.content[0].connection.t !== '' && v.content[0].connection.t === '0' ?
                                                                        <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" className="btn btn-cartaz">
                                                                            {language[lang].learn_more}
                                                                        </a>
                                                                    :

                                                                        v.content[0].connection.t !== undefined && v.content[0].connection.t !== '' && v.content[0].connection.t === '1' ?
                                                                            <a href={Hosts.WEB_SITE_URI + v.content[0].connection.link} className="btn btn-cartaz">
                                                                                {language[lang].learn_more}
                                                                            </a>
                                                                        :
                                                                            null
                                                                    
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                v.content[0].connection.id !== '' ?
                                                                    v.content[0].connection.slug !== undefined && v.content[0].connection.slug !== '' ?
                                                                        <a href={Hosts.WEB_SITE_URI + v.content[0].connection.slug} className="btn btn-cartaz">
                                                                            {language[lang].learn_more}
                                                                        </a>
                                                                    :
                                                                        <a href={Hosts.WEB_SITE_URI + 'pag/' + v.content[0].connection.id} /*target="_blank" rel="noreferrer"*/ /*title={language[lang].learn_more}*/ className="btn btn-cartaz">
                                                                            {language[lang].learn_more}
                                                                        </a>
                                                                : 
                                                                    null
                                                            }
                                                        </span>
                                                        
                                                    </SwiperSlide>
                                                </div>
                                        )
                                    })
                                :
                                    <SwiperSlide className={"slide-image slide-image"} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + repo_img_link("nophoto_banner.jpg") + ")", height: "calc(" + vhHeight + " - " + navbarHeight + "px)" }}>
                                    </SwiperSlide>
                                }

                                <div className="swiper-pagination-cartaz mb-3"></div>
                                <div className={"swiper-button-next swiper-cartaz-button-next " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>
                                <div className={"swiper-button-prev swiper-cartaz-button-prev " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>

                                <div className="col-12 d-none">
                                    <section id="slider-btn" className="demo mt-5">
                                        <span></span>
                                    </section>
                                </div>

                            </Swiper>
                        </div>
                        <svg className="hero-waves-slider" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
                            <defs>
                            <path id="wave-path-slider" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="wave1">
                            <use xlinkHref="#wave-path-slider" x="50" y="3"></use>
                            </g>
                            <g className="wave2">
                            <use xlinkHref="#wave-path-slider" x="50" y="0"></use>
                            </g>
                            <g className="wave3">
                            <use xlinkHref="#wave-path-slider" x="50" y="9"></use>
                            </g>
                        </svg>
                        <div className="static-banner-shape">
                            <img src={repo_img_link("banner-shape.png")} alt="banner-shape" title="banner-shape" />
                        </div>
                    </section>
                :
                    null
                }
            </>
    )
};
export default Slider;