import { createContext, useContext, useState } from "react";
import Loading from "../../../../loader";
import { nome_empresa } from "../HOSTS";
import { network } from "../UseService";
import { usarToken } from "../UseToken";
import { passwordHash } from "../../../../Utils";

import Swal from 'sweetalert2';
import func_ws from "../comunication_websocket";

let default_data = {
    is_logged: false,
    id_user: '',
    company_code: '',
    lista: [],
    numeroProdutos: 0,
    lista_carrinho: [],
    lista_events: [],
    ws_info: null,
    linguagem: '',
    timer_countdown: 0,
    intervalo_tolerancia_info: 0,
    numero_maximo_de_produtos_com_tempo: 3,
}

export const Context = createContext({
    on_init: () => { },
    getMainContext: () => { },
    setMainContext: () => { },
    verificar_e_conectar_wss: () => { },
    update_timer: () => { },
    set_lista_events: () => { },
    set_lista_carrinho: () => { },
    refreshlista: () => { },
    addprod: () => { },
    set_ws_info: () => { },
    updateprodpositive: () => { },
    removeprod: () => { },
    updateprodnegative: () => { },
    handle_login: () => { },
    func_loader: () => { },
    is_login_valid: () => { },
    get_is_logged: () => { },
    get_id_user: () => { },
    get_company_code: () => { },
    get_lista: () => { },
    get_numeroProdutos: () => { },
    get_lista_carrinho: () => { },
    get_lista_events: () => { },
    get_ws_info: () => { },
    get_linguagem: () => { },
    get_var_timer_countdown: () => { },
    set_var_timer_countdown: () => { },

});

export const ContextProvider = ({ children }) => {



    const [, set_var_refresh] = useState('')// apenas existe pois nao estava a conseguir atualizar info
    const [, set_lista_carrinho_state] = useState([]) // apenas existe pois nao estava a conseguir atualizatr o tempo
    const [, set_is_loading] = useState(false)
    const [, set_is_loading_login] = useState(false)



    const set_data_after_login = async (id_user, history_action, get_lista_carrinho) => {
        try {
            let temp_token_info = await usarToken().getToken()
            let token_info = JSON.parse(temp_token_info)


            default_data.is_logged = true
            default_data.company_code = token_info.user_info.companycode
            default_data.id_user = token_info.user_info.id
            default_data.linguagem = token_info.user_info.language

            if (default_data.is_logged === true) {
                //console.log('dentro do useeffect passou no assu+ync')
                if (default_data.ws_info === null) {

                    // console.log('default_data.ws_info === null2')

                    await verificar_e_conectar_wss(id_user, history_action, get_lista_carrinho)
                }



            }
        } catch (error) {
            console.log('set_data_after_login erro', error)
        }
    }
    const verificar_e_conectar_wss = async (id_users, history_action, get_lista_carrinho) => { // verifica se wss esta conectado, se nao tiver connecta
        try {

            let flag_ws_conected = false
            flag_ws_conected = func_ws().verify_if_wss_is_open(default_data.ws_info)
           /*  console.log('default_data.ws_info', default_data.ws_info)
            console.log('flag_ws_conected',flag_ws_conected)
           */  if (flag_ws_conected === false) {
                func_ws().close_ws(default_data.ws_info)
           // console.log('id_user', id_users)

                //    if (Object.keys(default_data.ws_info).length === 0) {

                /* console.log('flag_ws_conected apsosososososo', flag_ws_conected)
                console.log('default_data.linguagem', default_data.linguagem)
                console.log('default_data.id_user', default_data.id_user)
                console.log('default_data.linguagem', default_data.linguagem)
               */  await func_ws().open_connection_wss(update_timer, set_lista_events, set_lista_carrinho, id_users, default_data.lista_carrinho, refreshlista, default_data.linguagem, set_ws_info, get_id_user, get_lista_carrinho)
                //    }

            }
            return

        } catch (error) {
            console.log('verificar_e_conectar_wss error', error)
            return

        }
    }

    let is_login_valid = async (history_action, get_lista_carrinho) => {
        //  console.log('entrou no login_valid')
        try {
            let aux_token = await usarToken().getToken()
            let data_parse_token = JSON.parse(aux_token)
            /*  console.log('aux_token', JSON.stringify(aux_token))
             console.log('aux_token', data_parse_token.user_info.id)
  */
            // console.log('aux_token', aux_token.user_info.id)
            if (aux_token !== null) {
                default_data.is_logged = true
                await set_data_after_login(data_parse_token.user_info.id, history_action, get_lista_carrinho)

            } else {
                default_data.is_logged = false
            }
            // default_data.is_logged = (aux_token === null ? false : true)

        } catch (error) {
            console.log('login_valid error', error)
        }
    }
    async function handle_login(user_email, user_password, func_de_atualizacao, set_open_modal_login) {
        //console.log('entrou nho handle_login')
        try {
            func_de_atualizacao(true)

            let data_to_send = {
                companycode: '0000000',
                username: user_email,
                password: passwordHash(user_password),
            }

            let result = await network().login(data_to_send)
            console.log('result', result)


            if (result === true) {
                set_data_after_login()
                set_is_loading_login(true)
                set_is_loading(true)

                window.location.reload()

                set_open_modal_login(false)


            } else {
                // alert('credenciais invalidas')

                Swal.fire('Falha ao iniciar sessão', 'Dados de acesso incorretos', 'error')
            }

            func_de_atualizacao(false)
            set_is_loading(true)


        } catch (error) {

            console.log('handle_login error', error)
            // alert('Problema de ligação')

            Swal.fire('Falha ao iniciar sessão', 'Problema de ligação', 'error')

            set_is_loading(true)


        }
    }
    const func_loader = () => {
        return Loading()

    }


    let setMainContext = ({ is_logged, id_user, company_code, lista, numeroProdutos, lista_carrinho, lista_events, ws_info, linguagem }) => {
        try {
            default_data.is_logged = is_logged
            default_data.id_user = id_user
            default_data.company_code = company_code
            default_data.lista = lista
            default_data.numeroProdutos = numeroProdutos
            default_data.lista_carrinho = lista_carrinho
            default_data.lista_events = lista_events
            default_data.ws_info = ws_info
            default_data.linguagem = linguagem
        } catch (error) {
            console.log('setMainContext error', error)
        }
    }

    let getMainContext = () => {
        //console.log('passou getMainContext')
        return default_data
    }

    let update_timer = (new_obj) => {
        try {

            let aux = default_data.lista_carrinho

            let new_list = default_data.lista_carrinho.map((el) => {
                if (el.timestamp === new_obj.timestamp) {
                    el.tempo = new_obj.tempo
                    return el
                } else {
                    return el
                }
            })
            if (new_list.length !== 0) {
                // default_data.lista_carrinho.push(new_list)
                set_lista_carrinho_state(new_list)

                aux = new_list

            }
            default_data.lista_carrinho = aux
            set_var_refresh('4')
        } catch (error) {
            console.log('update_timer error', error)
        }
    }
    let set_lista_events = (new_list) => {
        try {
            //console.log('new_list set_lista_eventsset_lista_eventsset_lista_eventsset_lista_events', new_list)
            default_data.lista_events = new_list
        } catch (error) {
            console.log('set_lista_events error', error)
        }
    }
    let set_lista_carrinho = (new_list, flag_add_prods_to_lista_carrinho, lista_de_produtos_carrinho) => {
        /*   console.error('new_list', new_list)
          console.error('lista_de_produtos_carrinho', lista_de_produtos_carrinho) */
        try {

            let aux_numero_produtos = new_list.length
            set_lista_carrinho_state(new_list)
            let aux = []
            //let new_list_aux = []
            // let aux_list_prod_final = []

            if (new_list.length === 0) {
                //new_list_aux = []
            } else {
                new_list.forEach((el) => {
                    if (parseInt(el.quantidade) > 0) {
                        aux.push(el)
                    }
                })


                //2024  new_list_aux = [...new Set(aux_list_prod_final)]
                //aux_numero_produtos = new_list_aux.length
                //if (flag_add_prods_to_lista_carrinho === true && lista_de_produtos_carrinho.length !== 0 && lista_de_produtos_carrinho !== undefined) {// caso de ter entrado pela primeira vez
                // new_list_aux = lista_de_produtos_carrinho
                // aux_numero_produtos = lista_de_produtos_carrinho.length

                /* } else if (flag_add_prods_to_lista_carrinho === false && lista_de_produtos_carrinho !== undefined) {
                    new_list_aux = lista_de_produtos_carrinho
                    aux_numero_produtos = lista_de_produtos_carrinho.length
     
                } */
            }
            //console.error('default_data.lista_carrinho', default_data.lista_carrinho)
            default_data.numeroProdutos = aux_numero_produtos
            default_data.lista_carrinho = aux
            default_data.lista = aux//new_list_aux
            /* console.error('new_list_aux', new_list_aux)
            console.error('aux', aux)
            console.error('aux_numero_produtos1', aux_numero_produtos) */
            set_var_refresh('3')
        } catch (error) {
            console.log('set_lista_carrinho error', error)
        }
    }

    let refreshlista = () => {
        try {
            default_data.lista = []
            default_data.numeroProdutos = 0
            set_var_refresh('3')
        } catch (error) {
            console.log('refreshlista error', error)
        }
    }

    let addprod = (produtoadicionar) => {
        try {

            let aux_numero_produtos = default_data.numeroProdutos + 1
            default_data.lista.push({
                produto: produtoadicionar,
                quantidade: 1,
            })
            default_data.numeroProdutos = aux_numero_produtos

            set_var_refresh('2')

        } catch (error) {
            console.log('addprod error', error)
        }

    }
    let set_ws_info = (info) => {
        try {

            default_data.ws_info = info
        } catch (error) {
            console.log('set_ws_info error', error)
        }
    }
    let updateprodpositive = (produtoatualizarpositivo) => {
        try {

            const lista_aux2 = default_data.lista.map(el => {
                if (parseInt(el.id_stock) === parseInt(produtoatualizarpositivo)) {
                    el.quantidade += 1
                }
                return el
            });
            default_data.lista = lista_aux2
        } catch (error) {
            console.log('updateprodpositive error', error)
        }

    }
    let removeprod = (produtoremover) => {
        try {

            let lista_aux1 = []
            if (default_data.lista.length === 1) {
                lista_aux1 = []
            } else {

                default_data.lista.forEach(el => {

                    if (parseInt(el.produto.details[nome_empresa].id_stock) !== parseInt(produtoremover.produto.details[nome_empresa].id_stock)) {
                        lista_aux1.push(el)
                    }

                })
            }
            default_data.lista = lista_aux1
            default_data.numeroProdutos = lista_aux1.length
            set_var_refresh('5')
        } catch (error) {
            console.log('removeprod error', error)
        }
    }
    let updateprodnegative = (produtoatualizarnegativo) => {
        //console.log('updateprodnegative')
        try {

            const lista_aux3 = default_data.lista.map(el => {

                if (parseInt(el.produto.details[nome_empresa].id_stock) === parseInt(produtoatualizarnegativo)) {
                    el.quantidade = el.quantidade - 1
                }

                return el
            });
            default_data.lista = lista_aux3

            set_var_refresh('6')
        } catch (error) {
            console.log('removeprod error', error)
        }
    }
    /* 
    const set_is_logged = (flag) =>{
        default_data.is_logged = flag
     
    }
    const set_id_user = (flag) =>{
        default_data.id_user = flag
     
    }
    const set_company_code = (flag) =>{
        default_data.company_code = flag
     
    }
    const set_lista = (flag) =>{
        default_data.lista = flag
     
    }
    const set_numeroProdutos = (flag) =>{
        default_data.numeroProdutos = flag
     
    }
    const set_lista_carrinho = (flag) =>{
        default_data.lista_carrinho = flag
     
    }
    const set_lista_events = (flag) =>{
        default_data.lista_events = flag
     
    }
    const set_ws_info = (flag) =>{
        default_data.ws_info = flag
     
    }
    const set_linguagem = (flag) =>{
        default_data.linguagem = flag
     
    }
    
    */


    const on_init = async (set_is_loading, history_action, get_lista_carrinho) => {
        //console.log('estou na on_init')

        await is_login_valid(history_action, get_lista_carrinho)
        //if (default_data.is_logged === true) {
        //console.log('default_data.is_logged === true')
        /* if (default_data.ws_info === null) {
            console.log('default_data.ws_info === null')

            await verificar_e_conectar_wss()
            set_is_loading(false)
        } */



        // }
        set_is_loading(false)

    }
    const get_is_logged = () => {

        return default_data.is_logged
    }
    const get_id_user = () => {
        return default_data.id_user
    }
    const get_company_code = () => {
        return default_data.company_code
    }
    const get_lista = () => {

        /// console.log('»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»', default_data)

        return default_data.lista
    }
    const get_numeroProdutos = () => {
        return default_data.numeroProdutos
    }
    const get_lista_carrinho = () => {
        return default_data.lista_carrinho
    }
    const get_lista_events = () => {
        return default_data.lista_events
    }
    const get_ws_info = () => {
        return default_data.ws_info
    }
    const get_linguagem = () => {
        return default_data.linguagem
    }
    const get_intervalo_tolerancia = () => {
        return default_data.intervalo_tolerancia_info
    }
    const set_var_timer_countdown = async () => {
        try {
            let timer = await network().get_timer_countdown()
           // console.log('set_timer_countdown , timer', timer)
            //console.log('set_timer_countdown , Object.keys(timer).length', Object.keys(timer).length)
            if (Object.keys(timer).length === 0) {
                default_data.timer_countdown = 5
                default_data.intervalo_tolerancia_info = 15
                default_data.numero_maximo_de_produtos_com_tempo = 3

            } else {
                default_data.timer_countdown = timer.time_count_down
                default_data.intervalo_tolerancia_info = timer.intervalo_tolerancia
                default_data.numero_maximo_de_produtos_com_tempo = timer.numero_maximo_eventos_no_carrinho

            }
        } catch (error) {
            console.log('set_timer_countdown , error', error)
        }

    }
    const get_var_timer_countdown = () => {
        return default_data.timer_countdown


    }
    const get_var_numero_maximo_de_produtos_com_tempo = () => {
        return default_data.numero_maximo_de_produtos_com_tempo


    }



    return (
        <Context.Provider value={{
            on_init,
            getMainContext,
            setMainContext,
            update_timer,
            set_lista_events,
            set_lista_carrinho,
            refreshlista,
            addprod,
            set_ws_info,
            updateprodpositive,
            removeprod,
            updateprodnegative,
            verificar_e_conectar_wss,
            handle_login,
            func_loader,
            is_login_valid,
            get_is_logged,
            get_id_user,
            get_company_code,
            get_lista,
            get_numeroProdutos,
            get_lista_carrinho,
            get_lista_events,
            get_ws_info,
            get_linguagem,
            set_var_timer_countdown,
            get_var_timer_countdown,
            get_intervalo_tolerancia,
            get_var_numero_maximo_de_produtos_com_tempo,
        }}>
            {children}
        </Context.Provider>
    )
}

export default function useContextProvider() {
    return useContext(Context)
}

export function UsarContextProvider() {
    return useContext(Context)
} 