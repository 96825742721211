import React, { useState, useEffect } from 'react';

import ReactHtmlParser from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function MenuCards(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/menu/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info]);

    return (isloading ?
		    <Loader />
		:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="menu-cards my-5">
                <div id="menu-cards" className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-primario">{language[lang].menus}</h3>
                        </div>
                    </div>
                    {
                        content !== undefined && content.id !== '' ?
                            <div className="row">
                                <div className="div-content col-12 mb-3 mb-md-5">
                                    <h2 className="titulo-primario">
                                        {/*content['content'][0] !== undefined && content['content'][0]['title'] !== undefined && content.content[0].title !== '' ? content.content[0].title : 'Parceiros'*/}
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].title : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].title === undefined || content['content'][0].language[lang_id].title === '' ? content['content'][0].title : content['content'][0].language[lang_id].title))}
                                    </h2>
                                    <h2 className={content['content'][0] !== undefined && content['content'][0]['subtitle'] !== undefined && content.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                        {/*content['content'][0] !== undefined && content['content'][0]['subtitle'] !== undefined && content.content[0].subtitle !== '' ? content.content[0].subtitle : ''*/}
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].subtitle : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].subtitle === undefined || content['content'][0].language[lang_id].subtitle === '' ? content['content'][0].subtitle : content['content'][0].language[lang_id].subtitle))}
                                    </h2>
                                    <div className={"div-text"}>
                                        {/*content['content'][0] !== undefined && content['content'][0]['text'] !== undefined && content.content[0].text !== '' ? ReactHtmlParser(content.content[0].text) : ''*/}
                                        {ReactHtmlParser(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].text : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].text === undefined || content['content'][0].language[lang_id].text === '' ? content['content'][0].text : content['content'][0].language[lang_id].text))}
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }
                                
                    {
                        list_content.length > 0 ?
                            <div className="row justify-content-center">
                                {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-6 col-md-4 col-lg-3 mb-4">
                                                        <a href={'./menu/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}>
                                                            <div className="card">
                                                                <div className="menu-image">
                                                                    {
                                                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                            <AssecImage 
                                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                width="350"
                                                                                height="350"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                            />
                                                                        :
                                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                <AssecImage 
                                                                                    linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                    width="350"
                                                                                    height="350"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt={el['media']['image'][0]['name']}
                                                                                />
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                                <div className="card-body text-center">
                                                                    <div className="menu-title">
                                                                        {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                    </div>
                                                                    {/*<div className="service-text">
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                    </div>*/}                                                            
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        :
                         null
                    } 
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}