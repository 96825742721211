import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
//import { useLocation } from 'react-router-dom';
import './Footer_02.css';

import { language } from '../WEBContent/Language';

import { /*set_language,*/ /*repo_logo_link,*/ /*repo_site_documents_link,*/ translate, repo_site_assets_link, repo_img_link, content_language} from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = (props) => {

  const [, set_dados] = useState(null)
	const dados_config = useRef({})

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [lang_id, set_lang_id] = useState('1');

  //const location = useLocation().pathname;

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
      childs: []
    }
  }

  const navbar = useRef(data_interface_navbar);

  const [isloading, setLoading] = useState(true);

  useEffect(() => {
  
    if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
      set_dados(navbar.current = props.dados_navbar)
    }

    if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
      set_dados(dados_config.current = props.dados_config)
    }

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_lang_id('1')

      setLoading(false)
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })

      setLoading(false)
    }

  }, [props])
  
  //const page_location = useLocation().pathname.split('/').pop();

  return isloading ?
    null
    :
    <>
      <svg className="hero-waves-footer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
        <defs>
        <path id="wave-path-footer" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g className="wave1">
        <use xlinkHref="#wave-path-footer" x="50" y="3"></use>
        </g>
        <g className="wave2">
        <use xlinkHref="#wave-path-footer" x="50" y="0"></use>
        </g>
        <g className="wave3">
        <use xlinkHref="#wave-path-footer" x="50" y="9"></use>
        </g>
      </svg>
      <footer id="footer_02">
        <div className="static-banner-shape-footer">
            <img src={repo_img_link("banner-shape.png")} alt="banner-shape" title="banner-shape" />
        </div>
        <h4 className="footer-heading visually-hidden-focusable">
          Footer&nbsp;&#45;&nbsp;
          {
            dados_config.current.content !== undefined && dados_config.current.content[0].title !== undefined ?
              translate(dados_config.current.content[0].title, lang)
            :
              Hosts.webSiteTitle
          }
        </h4>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mx-auto mb-4 text-center">
              {/*<h5 className="mb-2">{language[lang].projeto_cofinanciado}</h5>*/}
                <a href="https://recuperarportugal.gov.pt/" target="_blank" rel="noreferrer" >
                  <img src={repo_site_assets_link("apoios_branco.svg")} alt="PRR - Recuperar Portugal" title="PRR - Recuperar Portugal" className="img-fluid" />
                </a>
            </div>
          </div>
          <div className="row gy-3">
            {/*<div className="col-12 d-none">
              <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} >
                <img src={repo_logo_link("logo.svg")} width="180" height="75" alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="img-fluid" />
              </a>
            </div>*/}
            
            <div className='col-12 footer-contacts'>
              <div className='flex-align-items'>
                {
                   dados_config.current.content[0]?.contacts?.address !== undefined && dados_config.current.content[0]?.contacts?.address !== '' ?
                   <>
                      <div className='flex-align-sub-items'>
                        <i className="bi bi-geo-alt"></i>
                        <span>
                          {/*dados_config.current.content[0].contacts.address*/}
                          {parse(dados_config.current.content[0]?.contacts?.address?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                        </span>
                      </div>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.tlf !== undefined & dados_config.current.content[0]?.contacts?.tlf !== '' ?
                    <>
                      <div className='flex-align-sub-items'>
                        <i className="bi bi-telephone"></i>
                        <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlf} target="_blank" rel="noreferrer">
                          <span>
                            {dados_config.current.content[0]?.contacts?.tlf}
                          </span>
                          <div><small>({language[lang].chamada_rede_fixa})</small></div>
                        </a>
                      </div>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.tlm !== undefined && dados_config.current.content[0]?.contacts?.tlm !== '' ?
                    <>
                      <div className='flex-align-sub-items'>
                        <i className="bi bi-phone"></i>
                        <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlm} target="_blank" rel="noreferrer">
                          <span>
                            {dados_config.current.content[0]?.contacts?.tlm}
                          </span>
                          <div><small>({language[lang].chamada_rede_movel})</small></div>
                        </a>
                      </div>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.email !== undefined && dados_config.current.content[0]?.contacts?.email !== '' ?
                    <div className='flex-align-sub-items'>
                      <i className="bi bi-envelope"></i>
                      <a href={"mailto:"+dados_config.current.content[0]?.contacts?.email} target="_blank" rel="noreferrer">
                        <span>
                          {dados_config.current.content[0]?.contacts?.email}
                        </span>
                      </a>
                    </div>
                  :
                     null
                }
              </div>
            </div>
            {/*<div className='col-12 text-center d-none'>
              <ul className='list-inline'>
                {
                  dados_config.current.content[0]?.contacts?.address !== '' ?
                   <>
                      <li className='list-inline-item'>
                        <span className='fw-bold'>
                          {language[lang].footer_morada}&#58;&nbsp;
                        </span>
                        {dados_config.current.content[0]?.contacts?.address}
                      </li>
                      <li className='list-inline-item'>&#124;</li>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.tlf !== '' ?
                    <>
                      <li className='list-inline-item'>
                        <span className='fw-bold'>
                          {language[lang].footer_tlf}&#58;&nbsp;
                        </span>
                        <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlf} target="_blank" rel="noreferrer">
                            {dados_config.current.content[0]?.contacts?.tlf}
                        </a>
                      </li>
                      <li className='list-inline-item'>&#124;</li>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.tlm !== '' ?
                    <>
                      <li className='list-inline-item'>
                        <span className='fw-bold'>
                          {language[lang].footer_tlm}&#58;&nbsp;
                        </span>
                        <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlm} target="_blank" rel="noreferrer">
                            {dados_config.current.content[0]?.contacts?.tlm}
                        </a>
                      </li>
                      <li className='list-inline-item'>&#124;</li>
                    </>
                  :
                    null
                }
                {
                  dados_config.current.content[0]?.contacts?.email !== '' ?
                    <li className='list-inline-item'>
                      <span className='fw-bold'>
                        {language[lang].footer_email}&#58;&nbsp;
                      </span>
                      <a href={"mailto:"+dados_config.current.content[0]?.contacts?.email} target="_blank" rel="noreferrer">
                          {dados_config.current.content[0]?.contacts?.email}
                      </a>
                    </li>
                  :
                     null
                }
              </ul>
            </div>*/}
            <div className="col-12 text-center social-media">
              <h5 className="my-2 visually-hidden">{language[lang].social_media}</h5>
              {
                dados_config.current.content !== undefined && dados_config.current.content[0].social !== undefined && dados_config.current.content[0].social.length > 0 ?
                  dados_config.current.content[0].social.map((el, k) => {
                    if(el.info.link !== '') {
                      return (
                        <a key={k} className="me-3" href={el.info.link.includes('http') ? el.info.link : 'http://' + el.info.link} title={el.info.name} target="_blank" rel="noreferrer" >
                          <i className={"bi bi-" + el.info.icon.bi}></i>
                        </a>
                      )
                    }
                    else {
                      return null
                    }
                  })
                :
                null
              }
            </div>
            <div className="col-12 text-center legal-info">
              <ul className='list-inline'>
                {
                  lang !== 'pt' && dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms[lang_id] !== undefined && dados_config.current.content[0]?.terms[lang_id] !== null && dados_config.current.content[0]?.terms[lang_id] !== '' ?
                    <>
                      <li className='list-inline-item'>
                        <a href={dados_config.current.content[0]?.terms[lang_id]?.link} target="_blank" rel="noreferrer">
                          <h6>{language[lang].terms_conditions}</h6>
                        </a>
                      </li>
                      <li className='list-inline-item slash'>
                        &#47;
                      </li>
                    </>
                  :
                    dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms['1'] !== null && dados_config.current.content[0]?.terms['1'] !== '' ?
                      <>
                        <li className='list-inline-item'>
                          <a href={dados_config.current.content[0]?.terms['1']?.link} target="_blank" rel="noreferrer">
                            <h6>{language[lang].terms_conditions}</h6>
                          </a>
                        </li>
                        <li className='list-inline-item slash'>
                          &#47;
                        </li>
                      </>
                    :
                      null
                }
                {/*<li className='list-inline-item slash'>
                  &#47;
                </li>*/}
                <li className='list-inline-item'>
                  <a href="https://www.livroreclamacoes.pt/" title={"Link - " + language[lang].complaint_book} target="_blank" rel="noreferrer">
                    <h6>{language[lang].complaint_book}</h6>
                  </a>
                </li>
            </ul>
            </div>
          </div>
        </div>
        <div className="col-12 copyright-info">
          <ul className="list-inline mb-0">
            <li className="list-inline-item p-0">
              <span>
                <i className="bi bi-c-circle"></i>
                &nbsp;2025&nbsp;
                {
                  dados_config.current.content !== undefined && dados_config.current.content[0].title !== undefined ?
                    translate(dados_config.current.content[0].title, lang)
                  :
                    Hosts.webSiteTitle
                }
              </span>
            </li>
            <li className="list-inline-item p-0">
              <span className="px-1">&#124;</span>
            </li>
            <li className="list-inline-item p-0">
              <span>{language[lang].developed}</span>
            </li>
            <li className="list-inline-item p-0">
              <span>
                <a href="http://sim.assec.pt" title="Website Assec Sim!" target="_blank" rel="noreferrer">
                  {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                  Assec Sim!
                </a>
              </span>
            </li>
          </ul>
        </div>
      </footer>
    </>


};
export default Footer;