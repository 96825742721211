import React, { useState, useEffect, useRef } from 'react';
import {Button } from "reactstrap";

//import Navbar from '../Navbar/Navbar';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from "../Footer/Footer_00";

import Loader from '../../../loader';

import './Forms.css';
import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import {
    // set_language,
    // reqGET,
	//reqPOST,
    //repo_banner_link,
    translate,
    content_language,
    functionSendMail

} from '../../../Utils';

import { language } from '../WEBContent/Language';

import Swal from 'sweetalert2';

const ContactForm = (props) => {

    const [ , set_dados ] = useState( null )
	const dados_config = useRef({})

    const navbar_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }
    const navbar = useRef(navbar_interface)

    const content_interface = {
        id: '',
        info: {},
        content: {}
    }
    const content = useRef(content_interface)

    const data_interface = {
        id: '',
        info: {
            companycode: Hosts.companycode,
            name: '',
            email: '',
            subject: '',
            message: '',
            rgpd: false,    
        }

    }
    const dados = useRef( data_interface )

    const [lang, set_lang] = useState('pt')
    const [lang_id, set_lang_id] = useState('1')

    const [isloading, setLoading] = useState(true)
   
    useEffect(() => { 

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados(dados_config.current = props.info.state.dados_config)
            set_dados(navbar.current = props.info.state.dados_navbar)
            set_dados(content.current = props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

			return
		}
    
    }, [props]); 

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    }

    const [counterText, setCounterText] = useState('');

    const handle_change_info = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info[name] = value)
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handle_change_info_textarea = (e) => {
        const { name, value } = e.target;
        //console.log('NAME ', name)
        //console.log('VALUE ', value)
        setCounterText(e.target.value)

        set_dados(dados.current.info[name] = value)
        //set_dados(dados.current.info.date.creation = new Date())
        //console.log('Dados Current:' + JSON.stringify(dados))
    }

    const handleSubmit = async e => {
        e.preventDefault();

        let mail_content =  '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
                '<td align="left" class style="padding:40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'+
                        '<h4>'+ language[lang].contact_form +':</h4>'+
                        '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.name + '</label></span>' +
                        '<br/>'+
                        '<span"><b>Email: </b><label>' + dados.current.info.email + '</label></span>'+
                        '<br/>'+
                        '<span><b>'+ language[lang].subject +': </b><label>' + dados.current.info.subject + '</label></span>' +
                        '<br/>'+
                        '<br/>' +
                        '<span><b>'+ language[lang].message +': </b><label>' + dados.current.info.message + '</label></span>' +
                        '<br/>'+
                    '</div>'+
                '</td>' +
            '</tr>' +
        '</table>'

        functionSendMail(dados_config.current.content[0].contacts.email_form, [dados.current.info.email], [dados_config.current.content[0].contacts.email_form], dados_config.current.info.name + ' - ' + language[lang].contact_form, mail_content, [], dados_config)
            .then((res) => {
                if(res === true) {
                    set_dados(dados.current = JSON.parse(JSON.stringify(data_interface)))
                    document.getElementById('agree').checked = false
                    setAgree(false)
                    //setRefresh(refresh + 1)
                    Swal.fire({
                        title: language[lang].contact_form,
                        text: language[lang].sent_successfully,
                        icon: 'success',
                        //showCancelButton: true,
                        confirmButtonColor: '#08396a',
                        //confirmButtonText: 'Tentar novamente',
                        })
                }
                else {
                    console.log('erro')
                }
            })

    }
        
    return (isloading ?
		<Loader />
    :
        <>
            <Navbar dados_config={dados_config.current} dados_navbar={navbar.current} />
            <Breadcrumb dados_config={dados_config.current} dados_navbar={navbar.current} dados_content={content.current} />
            <main className="contacts">
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <div className='info-container'>
                                <h1 className="titulo-primario mb-5">
                                    {language[lang].contacts}
                                </h1>
                                <div className='flex-align-items mb-3'>
                                    {
                                        dados_config.current.content[0]?.contacts?.tlf !== undefined && dados_config.current.content[0]?.contacts?.tlf !== '' ?
                                            <div className='flex-align-sub-items mb-3'>
                                                <i className="bi bi-telephone"></i>
                                                <span>
                                                    <h5>{language[lang].tlf}</h5>
                                                    <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlf} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0]?.contacts?.tlf}
                                                        <div><small>({language[lang].chamada_rede_fixa})</small></div>
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }

                                    {
                                        dados_config.current.content[0]?.contacts?.tlm !== undefined && dados_config.current.content[0]?.contacts?.tlm !== '' ?
                                            <div className='flex-align-sub-items mb-3'>
                                                <i className="bi bi-phone"></i>
                                                <span>
                                                    <h5>{language[lang].tlm}</h5>
                                                    <a href={"tel:"+dados_config.current.content[0]?.contacts?.tlm} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0]?.contacts?.tlm}
                                                        <div><small>({language[lang].chamada_rede_movel})</small></div>
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        dados_config.current.content[0]?.contacts?.email !== undefined && dados_config.current.content[0]?.contacts?.email !== '' ?
                                            <div className='flex-align-sub-items mb-3'>
                                                <i className="bi bi-envelope"></i>
                                                <span>
                                                    <h5>{language[lang].email}</h5>
                                                    <a href={"mailto:"+dados_config.current.content[0]?.contacts?.email} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0]?.contacts?.email}
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        dados_config.current.content[0]?.contacts?.address !== undefined && dados_config.current.content[0]?.contacts?.address !== '' && dados_config.current.content[0]?.contacts?.address !== '<p></p>\n' ?
                                            <div className='flex-align-sub-items mb-3'>
                                                <i className="bi bi-geo-alt"></i>
                                                <span>
                                                    <h5>{language[lang].adress}</h5>
                                                    {parse(dados_config.current.content[0]?.contacts?.address?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                                                </span>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        dados_config.current.content[0]?.contacts?.other !== undefined && dados_config.current.content[0]?.contacts?.other !== '' && translate(dados_config.current.content[0]?.contacts?.other, lang)  !== '<p></p>\n' ?
                                            <div className='flex-align-sub-items align-items-start'>
                                                <i className="bi bi-info-circle"></i>
                                                <span>
                                                    <h5>{language[lang].other_contacts}</h5>
                                                    {parse(translate(dados_config.current.content[0]?.contacts?.other, lang))}
                                                </span>
                                            </div>
                                        :
                                            null
                                    }

                                </div>

                                {/*<div className='d-none'>
                                    {
                                        dados_config.current.content[0].contacts.tlf !== '' ?
                                            <div className='mb-3'>
                                                <span>
                                                    <b>{language[lang].tlf}:&nbsp;</b>
                                                    <a href={"tel:"+dados_config.current.content[0].contacts.tlf} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0].contacts.tlf}
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }

                                    {
                                        dados_config.current.content[0].contacts.tlm !== '' ?
                                            <div className='mb-3'>
                                                <span>
                                                    <b>{language[lang].tlm}:&nbsp;</b>
                                                    <a href={"tel:"+dados_config.current.content[0].contacts.tlm} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0].contacts.tlm}
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        dados_config.current.content[0].contacts.email !== '' ?
                                            <div className='mb-3'>
                                                <span>
                                                    <b>{language[lang].email}:&nbsp;</b>
                                                    <a href={"mailto:"+dados_config.current.content[0].contacts.email} target="_blank" rel="noreferrer">
                                                        {dados_config.current.content[0].contacts.email}
                                                    </a>
                                                </span>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        dados_config.current.content[0].contacts.address !== '' ?
                                            <div>
                                                <div className='fw-bold mb-1'>{language[lang].adress}</div>
                                                {parse(dados_config.current.content[0].contacts.address.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
                                            </div>
                                        :
                                            null
                                    }
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-12 col-md-7 mt-5 mt-md-0">
                            <form className='form-container' onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        {language[lang].read_terms_conditions}
                                        {/*Leia os nossos <a href={Hosts.WEB_SITE_URI + "terms_conditions"} target="_blank" rel="noreferrer">termos e condições</a> antes de submeter o formulário. Ao submeter concorda com os termos do nosso serviço.*/}
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label className="form-label visually-hidden" htmlFor="name">{language[lang].name}*</label>
                                        <input id="name" type="text" name="name" value={dados.current.info.name} className="form-control" placeholder={language[lang].name + " *"} onChange={handle_change_info} required />
                                    </div>
                                    <div className="col-12 col-sm-6 mb-3">
                                        <label className="form-label visually-hidden" htmlFor="email">{language[lang].email}*</label>
                                        <input id="email" type="email" name="email" 
                                            value={dados.current.info.email} 
                                            className="form-control" 
                                            placeholder={language[lang].email + " *"} 
                                            onChange={handle_change_info} required
                                        /> 
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className="form-label visually-hidden" htmlFor="subject">{language[lang].subject}*</label>
                                        <input id="subject" type="subject" name="subject" 
                                            value={dados.current.info.subject} 
                                            className="form-control" 
                                            placeholder={language[lang].subject + " *"} 
                                            onChange={handle_change_info} required
                                        /> 
                                    </div>
                                    <div className="col-12">
                                        <label className="form-label visually-hidden" htmlFor="message">{language[lang].message}*</label>
                                        <textarea
                                            style={{ height: "180px", width: "100%" }}
                                            id="message"
                                            name="message"
                                            className="form-control"
                                            placeholder={language[lang].message + " *"}
                                            maxLength="600"
                                            value={dados.current.info.message}
                                            onChange={handle_change_info_textarea}
                                            required
                                        />
                                        <div className="text-end fw-bold my-2 small">
                                            {language[lang].maximum_numbers_of_caracters}&#58;&nbsp;{counterText.length}/600
                                        </div>
                                        {/* <TextareaCounter countLimit={200} style={{height: "100px", width: "100%"}} id="message" name="message" value={dados.current.info.message} onChange={handle_change_info} required/>
                                        <input type="text" name="message" value={dados.current.info.message} className="form-control form-control-sm" placeholder="" onChange={handle_change_info} /> */}
                                    </div>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                            <label className="form-check-label small" htmlFor="agree">{language[lang].agree_terms_conditions}</label>&nbsp;
                                            {
                                                lang !== 'pt' && dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms[lang_id] !== undefined && dados_config.current.content[0]?.terms[lang_id] !== null && dados_config.current.content[0]?.terms[lang_id] !== '' ?
                                                    <a className="small" href={dados_config.current.content[0]?.terms[lang_id]?.link} target="_blank" rel="noreferrer">
                                                        {language[lang].terms_conditions_link}
                                                    </a>
                                                :
                                                    dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms['1'] !== null && dados_config.current.content[0]?.terms['1'] !== '' ?
                                                        <a className="small" href={dados_config.current.content[0]?.terms['1']?.link} target="_blank" rel="noreferrer">
                                                            {language[lang].terms_conditions_link}
                                                        </a>
                                                    :
                                                        <a className="small pe-none" href='!#' target="_blank" rel="noreferrer">
                                                            {language[lang].terms_conditions_link}
                                                        </a>
                                            }
                                            {/*<a className="small" href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>*/}
                                        </div>
                                        <div className="small">{language[lang].terms_conditions_required}</div>
                                    </div>

                                    <div className='col-12 text-end mt-5'>
                                        {/*{console.log('AGREE2', agree)}
                                        {console.log('MAIL: ', JSON.stringify(mailOptions))}*/}
                                        <Button disabled={!agree} className="mx-auto" color="primary">{language[lang].send}</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    dados_config.current.content[0].contacts.gps !== undefined && dados_config.current.content[0].contacts.gps.lat !== '' && dados_config.current.content[0].contacts.gps.lng !== '' ?
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe width="100%" height="500" id="gmap_canvas" title="gmap_canvas" src={"https://maps.google.com/maps?q=" + dados_config.current.content[0].contacts.gps.lat + "," + dados_config.current.content[0].contacts.gps.lng + "&hl=pt&z=18&maptype=satellite&output=embed"} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    :
                     null
                }

            </main>
            <Footer dados_config={dados_config.current} dados_navbar={navbar.current} />
        </>
    )

};

export default ContactForm;