import React, { useState, useEffect, useRef } from 'react';

import { content_language, translate } from '../../../../Utils';

// import { language } from '../Language';

import parse from 'html-react-parser';

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeMote_03.css"

const HomeMote = (props) => {

	const [, set_dados] = useState(null)
	const dados_config = useRef([])
	const [mote_title, set_mote_title] = useState('')
  const [mote_subtitle, set_mote_subtitle] = useState('')
	const mote_text = useRef('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');


    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'mote' && el.info.active) { 
                  set_mote_title(translate(el.info.title, lang))
                  set_mote_subtitle(translate(el.info.subtitle, lang))
                  set_dados(mote_text.current = translate(dados_config.current.content[0]?.mote?.text, lang))
                  setLoading(false)
                }
                
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homemote_03" className='div-component'>
              <div className="container">
                <div className="row">
                  {
                    mote_title !== '' || mote_subtitle !== '' ?
                        <div className="col-12 title-properties">
                          {
                              mote_title !== '' ?
                                  <h3 className="titulo-pp">
                                      {mote_title}
                                  </h3>
                              :
                                null
                          }
                          {
                              mote_subtitle !== '' ?
                                  <h4 className={"subtitulo-pp"}>
                                      {mote_subtitle}
                                  </h4>
                              :
                                  null
                          }
                          <hr></hr>
                        </div>
                    :
                        null 
                  }
                  <div className='col-12 col-md-9 mx-auto'>
                    <div className='text-columns'>
                      { parse(mote_text.current) }
                    </div>
                  </div>
                  {/*
                  <div className="col-12 mt-3 text-center">
                    <a className="btn btn-primary" href={'./sobre-nos'} >
                      {language[lang].learn_more}
                    </a>
                  </div>*/}
                </div>
              </div>
          </section>
    )
};
export default HomeMote;