import React, { useState, useEffect } from 'react';
//import { useLocation } from 'react-router-dom';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, translate, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function NewsCards(props) {
    //const { pathname } = useLocation();

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [news_title, set_news_title] = useState('')
    const [news_subtitle, set_news_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            for (let el of props.info.state.dados_config.content[0].components) {

                if(el.info.tag === 'news' && el.info.active) {
                    set_news_title(translate(el.info.title, lang))
                    set_news_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published)
                    
                                  if(hoje >= date_p) {
                                    if(v.info.date.avaiable !== '') {
                                        var date_a = new Date(v.info.date.avaiable);

                                        if(hoje <= date_a) {
                                            aux.push(v)
                                        }
                                    }
                                    else {
                                        aux.push(v)
                                    }
                                  }
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info, lang]);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="news-cards my-5">
                <div id="news-cards" className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {news_title}
                            </h3>
                            {
                                news_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {news_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                    </div>
                    {
                        list_content.length > 0 ?
                            <div className="row justify-content-center">
                                {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-5">
                                                        <div className="card">
                                                            <a href={'./' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : 'article/' + content.id)} title={(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}>
                                                                <div className="noticia-img"> 
                                                                    {
                                                                    lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                        <AssecImage 
                                                                            linkImage={el['language'][lang_id]['media']['image'][0]['link'] !== '' ? el['language'][lang_id]['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                        />
                                                                    :
                                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                            <AssecImage 
                                                                                linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['media']['image'][0]['name']}
                                                                            />
                                                                        :
                                                                            <AssecImage 
                                                                                linkImage={repo_img_link('nophoto.webp')}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={'no_photo'}
                                                                            />
                                                                    }
                                                                </div>
                                                            </a>
                                                            <div className="card-body">
                                                                <div className="timestamp">{parse(content.info.date.published.split('T')[0])}</div>
                                                                <div className="card-title">
                                                                    {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                </div>
                                                                <div className="card-text">
                                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                </div> 
                                                            </div>
                                                            <div className="px-3 text-end mb-3">
                                                                <a className="btn btn-text" href={'./article/' + + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)}>{language[lang].learn_more}<i className="bi bi-chevron-right"></i></a>
                                                            </div>

                                                            <div className="card-footer text-muted d-none">
                                                                <i className="bi bi-tag pe-2" />
                                                                {
                                                                    content.info.tags.map((v, k) => {
                                                                        return (
                                                                            <span key={k} className="pill-tag">{v.text}</span>
                                                                            )
                                                                        })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        : null
                    }
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}